import { accessManager } from '@cedalo/webui/src/helper/AccessManager';
import ConfigManager from '@cedalo/webui/src/helper/ConfigManager';
import gatewayClient from '@cedalo/webui/src/helper/GatewayClient';
import { withAppData } from '@cedalo/webui/src/ui/app/AppProvider';
import { useQuery } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import DialogButton from '@cedalo/webui/src/ui/utils/DialogButton';
import { Overlay } from '@cedalo/webui/src/ui/utils/Overlay';
import SettingsPaper from '@cedalo/webui/src/ui/utils/SettingsPaper';
import TableSortHeader from '@cedalo/webui/src/ui/utils/TableSortHeader';
import CheckIcon from '@mui/icons-material/esm/Check';
import EditIcon from '@mui/icons-material/esm/Edit';
import {
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	FormControl,
	FormHelperText,
	IconButton,
	Input,
	InputAdornment,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	Tooltip
} from '@mui/material';
import { saveAs } from 'file-saver';
import fetch from 'isomorphic-fetch';
import React, { useEffect, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const GATEWAY_CONFIG = ConfigManager.config.gatewayClientConfig;

const cellStyle = {
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
};

const MACHINE_DATA_QUERY = `
query MachineData($machineId: ID!) {
	machine(id: $machineId) {
		files {
			name
			lastModified
			path
		}
	}
}
`;

const RENAME_FILE_MUTATION = `
mutation RenameDeleteFile($machineId: ID!, $oldName: String!, $newName: String!) {
	renameMachineFile(machineId: $machineId, oldName: $oldName, newName: $newName) {
		success
		code
	}
}
`;

const DELETE_FILES_MUTATION = `
mutation DeleteFiles($machineId: ID!, $fileNames: [String!]!) {
	deleteMachineFiles(machineId: $machineId, files: $fileNames) {
		success
		code
	}
}
`;

const doDownload = async ({ name, path }) => {
	try {
		const response = await fetch(`${GATEWAY_CONFIG.restEndpointURL}${path.replace('/api/v1.0', '')}`, {
			method: 'GET',
			headers: new Headers([['Authorization', `JWT ${accessManager.authToken}`]])
		});
		const blob = await response.blob();
		saveAs(blob, name);
	} catch (error) {
		console.error(`Failed to download file: ${name}`, error);
	}
};

const doDeleteFiles = async (machineId, fileNames) => {
	try {
		const data = await gatewayClient.graphql(DELETE_FILES_MUTATION, { machineId, fileNames });
		const { success, code } = data.deleteMachineFiles;
		if (!success) {
			console.error(`Failed to delete files: ${fileNames}. Reason: ${code}`);
		}
		return success;
	} catch (error) {
		console.error(`Failed to delete files: ${fileNames}`, error);
		return false;
	}
};

const doRenameFile = async (machineId, oldName, newName) => {
	try {
		const data = await gatewayClient.graphql(RENAME_FILE_MUTATION, { machineId, oldName, newName });
		const { success, code } = data.renameMachineFile;
		if (!success) {
			console.error(`Failed to rename file: ${oldName}. Reason: ${code}`);
		}
		return success;
	} catch (error) {
		console.error(`Failed to rename file: ${oldName}`, error);
		return false;
	}
};

const DeleteDialog = (props) => {
	const { open, selection } = props;
	const count = Object.values(selection).filter((s) => s).length;
	return (
		<Dialog open={open} maxWidth="sm" fullWidth>
			{props.overlay}
			<DialogTitle>
				<FormattedMessage id="Extensions.Machinedata.DeleteDialog.Title" defaultMessage="Delete File" />
			</DialogTitle>
			<DialogContent style={{ paddingTop: '15px' }}>
				<FormattedMessage
					id="Extensions.Machinedata.DeleteDialog.Message"
					defaultMessage={`Do you want to permanently delete {count, number} selected {count, plural, one {file} other {files}}?`}
					values={{ count }}
				/>
			</DialogContent>
			<Divider />
			<DialogActions>
				<DialogButton onClick={props.onCancel}>
					<FormattedMessage id="Cancel" defaultMessage="Cancel" />
				</DialogButton>
				<DialogButton data-action="confirm" onClick={props.onConfirm} autoFocus>
					<FormattedMessage
						id="Extensions.Machinedata.DeleteDialog.DeleteButton"
						defaultMessage="Delete {count, plural, one {File} other {Files}}"
						values={{ count }}
					/>
				</DialogButton>
			</DialogActions>
		</Dialog>
	);
};

const formatDate = (date) => {
	const d = new Date(date);
	return `${d.toLocaleDateString(undefined, {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit'
	})} ${d.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
};

const forbiddenFilenames = [
	'CON',
	'PRN',
	'AUX',
	'NUL',
	'COM1',
	'COM2',
	'COM3',
	'COM4',
	'COM5',
	'COM6',
	'COM7',
	'COM8',
	'COM9',
	'LPT1',
	'LPT2',
	'LPT3',
	'LPT4',
	'LPT5',
	'LPT6',
	'LPT7',
	'LPT8',
	'LPT9'
];

const forbiddenCharactersRegex = /[<>:"/\\|?*]/g;

const RenameDialog = (props) => {
	const { fileName, otherNames } = props;
	const fileExtension = fileName.split('.').slice(-1)[0];
	const [newName, setNewName] = useState(() => {
		const parts = fileName.split('.');
		return parts.slice(0, parts.length - 1).join('.');
	});

	return (
		<Dialog open maxWidth="sm" fullWidth>
			{props.overlay}
			<DialogTitle>
				<FormattedMessage id="Extensions.Machinedata.RenameDialog.Title" defaultMessage="Rename File" />
			</DialogTitle>
			<DialogContent style={{ paddingTop: '15px' }}>
				<FormControl fullWidth>
					<Input
						value={newName}
						onChange={(event) => setNewName(event.target.value.replace(forbiddenCharactersRegex, ''))}
						endAdornment={<InputAdornment position="end">{`.${fileExtension}`}</InputAdornment>}
					/>

					<FormHelperText id="weight-helper-text">
						<FormattedMessage
							id="Extensions.Machinedata.RenameDialog.InputLabel"
							defaultMessage="New filename"
							values={{ fileName }}
						/>
					</FormHelperText>
				</FormControl>
			</DialogContent>
			<Divider />
			<DialogActions>
				<DialogButton onClick={props.onCancel}>
					<FormattedMessage id="Cancel" defaultMessage="Cancel" />
				</DialogButton>
				<DialogButton
					data-action="confirm"
					onClick={() => props.onConfirm(`${newName}.${fileExtension}`)}
					autoFocus
					disabled={
						!newName ||
						forbiddenFilenames.includes(newName) ||
						otherNames.includes(`${newName}.${fileExtension}`)
					}
				>
					<FormattedMessage
						id="Extensions.Machinedata.RenameDialog.RenameButton"
						defaultMessage="Rename File"
					/>
				</DialogButton>
			</DialogActions>
		</Dialog>
	);
};

const filesReducer = (state, action) => {
	switch (action.type) {
		case 'reset':
			return {
				selection: {},
				files: null
			};
		case 'init':
			return {
				selection: Object.fromEntries(action.files.map((f) => [f.name, false])),
				files: action.files
			};
		case 'delete': {
			const deleted = new Set(action.files);
			return {
				...state,
				files: state.files.filter((f) => !deleted.has(f.name)),
				selection: Object.fromEntries(Object.entries(state.selection).filter(([f]) => !deleted.has(f)))
			};
		}
		case 'rename': {
			const { [action.oldName]: selected, ...selection } = state.selection;
			return {
				...state,
				files: state.files.map((f) => (f.name === action.oldName ? { ...f, name: action.newName } : f)),
				selection: {
					...selection,
					[action.newName]: selected
				}
			};
		}
		case 'toggle':
			return {
				...state,
				selection: {
					...state.selection,
					[action.key]: !state.selection[action.key]
				}
			};
		case 'toggleAll': {
			const newState = !Object.values(state.selection).every((x) => x);
			return {
				...state,
				selection: Object.fromEntries(Object.entries(state.selection).map(([k]) => [k, newState]))
			};
		}
		default:
			throw new Error(`Invalid action; ${action.type}`);
	}
};

export const MachineDataPanel = (
	withAppData(({ machine }) => ({ machineId: machine.id }))(({ machineId, onClose, open }) => {
		const [orderBy, setOrderBy] = useState('name');
		const [sortOrder, setSortOrder] = useState('asc');
		const [openDelete, setOpenDelete] = useState(false);
		const [renameFile, setRenameFile] = useState(null);
		const [requestState, setRequestState] = useState('not-fetched');
		const [reloadCounter, setReloadCounter] = useState(0);
		const [state, dispatch] = useReducer(filesReducer, {
			selection: {},
			files: null
		});
		const onDelete = () => setOpenDelete(true);
		const onToggleFile = (file) => dispatch({ type: 'toggle', key: file });
		const onToggleAll = () => dispatch({ type: 'toggleAll' });
		const onDownload = doDownload;
		const onRename = (fileName, otherNames) => setRenameFile({ fileName, otherNames });

		const filesQuery = useQuery(MACHINE_DATA_QUERY, { machineId, reloadCounter });
		const loading = filesQuery.status === 'loading';
		const errors = filesQuery.status === 'error' && filesQuery.result;

		useEffect(() => {
			const files = filesQuery.status === 'done' && filesQuery.result.machine.files;
			if (files) {
				dispatch({ type: 'init', files });
			} else {
				dispatch({ type: 'reset' });
			}
		}, [filesQuery]);

		const { selection, files } = state;
		const direction = sortOrder === 'asc' ? 1 : -1;
		const sortedFiles = files ? [...files].sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1) * direction) : [];

		const allSelected = !!files && files.length && Object.values(selection).every((x) => x);
		const someSelected = !allSelected && Object.values(selection).some((x) => x);

		return (
			<SettingsPaper
				open={open}
				close={onClose}
				scrollable={false}
				style={{ width: '600px' }}
				helpContext="guides/app/appfiles"
				title={<FormattedMessage id="Extensions.Machinedata.Dialog.Title" defaultMessage="Files" />}
			>
				<div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<Button
						onClick={onDelete}
						disabled={!(allSelected || someSelected)}
						variant="outlined"
						sx={{ m: 1 }}
					>
						<FormattedMessage id="Extensions.Button.Delete" defaultMessage="Delete" />
					</Button>
				</div>
				<DeleteDialog
					open={!!openDelete}
					onConfirm={async () => {
						setRequestState('fetching');
						const fileNames = Object.entries(state.selection)
							.filter(([, v]) => v)
							.map(([k]) => k);
						const success = await doDeleteFiles(machineId, fileNames);
						if (!success) {
							setReloadCounter(reloadCounter + 1);
						}
						setRequestState('fetched');
						setOpenDelete(false);
						dispatch({ type: 'delete', files: fileNames });
					}}
					onCancel={() => setOpenDelete(false)}
					selection={state.selection}
					overlay={
						<Overlayed
							requestState={requestState}
							doneCb={() => {
								setRequestState('not-fetched');
							}}
						/>
					}
				/>

				{renameFile ? (
					<RenameDialog
						onConfirm={async (newName) => {
							setRequestState('fetching');
							await doRenameFile(machineId, renameFile.fileName, newName);
							dispatch({ type: 'rename', oldName: renameFile.fileName, newName });
							setRequestState('fetched');
						}}
						onCancel={() => setRenameFile('')}
						fileName={renameFile.fileName}
						otherNames={renameFile.otherNames}
						overlay={
							<Overlayed
								requestState={requestState}
								doneCb={() => {
									setRenameFile(null);
									setRequestState('not-fetched');
								}}
							/>
						}
					/>
				) : null}
				<TableContainer sx={{ overflowY: 'auto', height: 'calc(100% - 56px)' }}>
					<Table stickyHeader size="small" sx={{ tableLayout: 'fixed' }}>
						<TableSortHeader
							height={48}
							cells={[
								{
									component: (
										<TableCell
											padding="none"
											style={{
												width: '10%'
											}}
											// padding="none"
											// padding="default"
											align="left"
											key="checkbox"
										>
											<Checkbox
												indeterminate={someSelected}
												checked={allSelected}
												onChange={onToggleAll}
											/>
										</TableCell>
									)
								},
								{
									id: 'name',
									numeric: false,
									label: 'Extensions.File.Name',
									width: '57%',
									padding: false
								},
								{
									id: 'lastModified',
									numeric: false,
									label: 'Extensions.File.LastModified',
									width: '20%',
									padding: false
								},
								{
									component: (
										<TableCell
											padding="normal"
											style={{
												width: '13%'
											}}
											// padding="none"
											// padding="default"
											align="left"
											key="empty"
										/>
									)
								}
							]}
							orderBy={orderBy}
							order={sortOrder}
							onRequestSort={(event, sortProperty) => {
								const order = orderBy === sortProperty && sortOrder === 'desc' ? 'asc' : 'desc';
								setOrderBy(sortProperty);
								setSortOrder(order);
							}}
							// onFieldToggle={(field, state) => console.log('TOGGLE', field, state)}
						/>

						<TableBody>
							{loading ? (
								<TableRow
									style={{
										height: '35px'
									}}
									tabIndex={-1}
								>
									<TableCell colSpan={4} align="center">
										<FormattedMessage
											id="Extensions.Machinedata.Dialog.Loading"
											defaultMessage="Loading files..."
										/>
									</TableCell>
								</TableRow>
							) : null}
							{errors ? (
								<TableRow
									style={{
										height: '35px'
									}}
									tabIndex={-1}
								>
									<TableCell colSpan={4} align="center">
										<FormattedMessage
											id="Extensions.Machinedata.Dialog.Error"
											defaultMessage="'An unexpected error occurred!'"
										/>
									</TableCell>
								</TableRow>
							) : null}
							{files && files.length > 0
								? sortedFiles.map((file) => (
										<TableRow
											style={{
												height: '35px',
												cursor: 'pointer'
											}}
											hover
											// onClick={() => this.handleStreamChange(file)}
											tabIndex={-1}
											key={file.name}
										>
											<TableCell padding="none" onClick={() => onToggleFile(file.name)}>
												<Checkbox checked={selection[file.name] || false} />
											</TableCell>
											<TableCell
												padding="none"
												onClick={() => onToggleFile(file.name)}
												style={cellStyle}
											>
												<Tooltip enterDelay={300} title={file.name}>
													<span>{file.name}</span>
												</Tooltip>
											</TableCell>
											<TableCell
												padding="none"
												onClick={() => onToggleFile(file.name)}
												style={cellStyle}
											>
												{formatDate(file.lastModified)}
											</TableCell>
											<TableCell
												padding="none"
												style={cellStyle}
											>
												<Tooltip
													enterDelay={300}
													title={
														<FormattedMessage
															id="Extensions.Machinedata.Download.Tooltip"
															defaultMessage="Download"
														/>
													}
												>
													<IconButton onClick={() => onDownload(file)} size="large">
														<SvgIcon>
															<path d="M5,20H19V18H5M19,9H15V3H9V9H5L12,16L19,9Z" />
														</SvgIcon>
													</IconButton>
												</Tooltip>
												<Tooltip
													enterDelay={300}
													title={
														<FormattedMessage
															id="Extensions.Machinedata.Rename.Tooltip"
															defaultMessage="Rename"
														/>
													}
												>
													<IconButton
														onClick={() =>
															onRename(
																file.name,
																files.filter((f) => f.name !== file.name)
															)
														}
														size="large"
													>
														<EditIcon />
													</IconButton>
												</Tooltip>
												{/* <Tooltip
												enterDelay={300}
												title={
													<FormattedMessage
														id="Extensions.Machinedata.Delete.Tooltip"
														defaultMessage="Delete"
													/>
												}
											>
												<IconButton onClick={() => props.onDelete(file.name)}>
													<DeleteIcon />
												</IconButton>
											</Tooltip> */}
											</TableCell>
										</TableRow>
								  ))
								: null}
							{!loading && files && files.length === 0 ? (
								<TableRow
									style={{
										height: '35px'
									}}
									tabIndex={-1}
								>
									<TableCell colSpan={4} align="center">
										<FormattedMessage
											id="Extensions.Machinedata.Dialog.NoFiles"
											defaultMessage="No files found"
										/>
									</TableCell>
								</TableRow>
							) : null}
						</TableBody>
					</Table>
				</TableContainer>
			</SettingsPaper>
		);
	})
);

const Overlayed = (props) => {
	const { requestState } = props;
	const [reallyDone, setReallyDone] = useState(false);

	useEffect(() => {
		if (requestState === 'fetching') {
			setReallyDone(false);
		} else if (requestState === 'fetched') {
			setTimeout(() => setReallyDone(true), 500);
		}
	}, [requestState]);

	useEffect(() => {
		if (reallyDone) {
			props.doneCb();
		}
	}, [reallyDone]);

	if (requestState === 'fetching') {
		return (
			<Overlay>
				<CircularProgress style={{ width: '24px', height: '24px' }} />
			</Overlay>
		);
	}
	if (requestState === 'fetched') {
		return (
			<Overlay>
				<CheckIcon color="primary" />
			</Overlay>
		);
	}
	return null;
};

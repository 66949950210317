import React from 'react';

export default [
	{
		selector: '[data-tour="page-app"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					This is a Streamsheets App. On the first glimpse, it looks very much like a regular spreadsheet page,
					but have a  closer look, there are some additions, which allow for the static spreadsheet
					character to transform into a dynamic Streamsheet.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="app-control"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Streamsheets are dynamic, because they calculate their contents not only once, but based on trigger. To activate these trigger you have to start your App first. At the top you find the App controls, which allow you to start and stop an App.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		position: [280, 150],
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Every sheet has its own Inbox. Click the inbox title to open the Inbox settings.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="settings-sidepanel"]',
		hash: 'panel=/InboxSettings/settings',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Here you can set the different triggers called calculation modes. By default, a sheet is set to an interval trigger of one second. But there are further  modes to choose from.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="settings-sidepanel"]',
		hash: 'panel=/InboxSettings/connections',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>The Inbox also allows you to link connections to it, in order to subscribe to a data stream. In a new App, there  are not yet any connections defined. With &quot;Create Connection&quot; it is possible to set up a connection and link it to this inbox.
					If an Inbox has a Connection assigned (and is started) messages will arrive. The payload of a message can be referenced to the sheet via Drag&Drop.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="button-connections"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Connections can also be accessed using this icon here.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="settings-sidepanel"]',
		hash: 'panel=/Connections/',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Connections connect an App to the outside world.
					They can be either connected to an Inbox or used in specific functions.
					For example: An MQTT Connection can either be used in an Inbox to subscribe to a MQTT topic or it can be used in the MQTT.PUBLISH() function to publish messages.
					A new connection can be created via the &quot;+&quot; icon in the top right corner.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		hash: '',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					The main action happens on the grid. There are a huge number of functions you can choose from, to implement the logic your use case needs.
				</p>
				<p>
					Very important note:
					Streamsheets have a set calculation order.
				</p>
				<p>
					The calculation process of a sheet starts in the top left corner of the grid in the IF1 cell,
					moving the calculation process further to A1, B1, etc. When all cells in the first row are processed,
					the calculation moves to row two. This is repeated until all cells are processed. The sheet waits for the next trigger to start the calculation process again.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="sheet-toolbar"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>This is the toolbar. Use it to apply formats, add charts and shapes and also access the Inbox settings.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="sheet-tabs"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>There can be not one, but multiple sheets in every App. Add up to six sheets or even a dashboard sheet by using the &quot;+&quot; here.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="app-actions"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Besides the connections, here in the top right you can find a number of useful settings.
					Mark an App as a favorite, open the integration account settings and open/define the presentation mode.
					More settings can be found behind the drop-down menu.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="button-tour"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Tour</p>
				<p>Select another tour to continue.</p>
			</div>
		),
	},
];

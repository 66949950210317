import React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@mui/icons-material/esm/InfoOutlined';
import { Tooltip } from '@mui/material';

// InfoLabel
function LabelWithInfo({ labelId, labelDefault, labelValues, infoId, infoDefault, infoValues }) {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<FormattedMessage id={labelId} defaultMessage={labelDefault} values={labelValues} />
			<Tooltip
				enterDelay={300}
				title={<FormattedMessage id={infoId} defaultMessage={infoDefault} values={infoValues} />}
			>
				<InfoIcon
					style={{
						marginLeft: '10px',
						fill: '#888888',
						fontSize: '1rem',
						paddingBottom: '2px'
					}}
				/>
			</Tooltip>
		</div>
	);
}
export default LabelWithInfo;

import React, {Component} from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import {FormattedMessage} from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ImageList from '@mui/material/ImageList';
import PropTypes from 'prop-types';

const chartButtonStyle = { height: '40px', width: '40px' };
const chartImageStyle = { height: '35px', width: '35px' };

export class ChartExtensions extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	static propTypes = {
		onCreatePlot: PropTypes.func.isRequired,
		titleColor: PropTypes.string.isRequired,
	};

	render() {
		const gridTitleStyle = {
			backgroundColor: this.props.titleColor,
			color: this.props.titleFontColor,
			fontSize: '9pt',
			padding: '3px',
			height: '16px',
			display: 'flex',
			alignItems: 'center',
		};
		return (
            <ImageList
				cols={6}
				rowHeight={40}
				gap={4}
				style={{
					width: '270px',
					margin: '1px'
				}}
			>
				<ImageListItem
					cols={6}
					style={{
						height: '24px'
					}}
				>
					<div style={gridTitleStyle}>
						<FormattedMessage id="StreamChart.TypesGauge" defaultMessage="Gauge Charts"/>
					</div>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.Gauge" defaultMessage="Gauge"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('gauge')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/gauge.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.Gauge23" defaultMessage="Gauge 2/3"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('gauge23')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/gauge23.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.GaugeValue" defaultMessage="Gauge Value"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('gaugevalue')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/gaugevalue.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.GaugePointer" defaultMessage="Gauge Pointer"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('gaugepointer')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/gaugepointer.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem
					cols={6}
					style={{
						height: '24px'
					}}
				>
					<div style={gridTitleStyle}>
						<FormattedMessage id="StreamChart.TypesState" defaultMessage="State Charts"/>
					</div>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.StateColumn" defaultMessage="State Column"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('statecolumn')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/statecolumn.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.StateTime" defaultMessage="State Time"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('statetime')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/statetime.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.StatePeriod" defaultMessage="State Period"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('stateperiod')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/stateperiod.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem
					cols={6}
					style={{
						height: '24px'
					}}
				>
					<div style={gridTitleStyle}>
						<FormattedMessage id="StreamChart.TypesStock" defaultMessage="Stock Charts"/>
					</div>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.HighLowClose" defaultMessage="High Low Close"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('stockHLC')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/stockhlc.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.OpenHighLowClose" defaultMessage="Open High Low Close"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('stockOHLC')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/stockohlc.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.VolumeHighLowClose" defaultMessage="Volume High Low Close"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('stockVHLC')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/stockvhlc.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.VolumeOpenHighLowClose" defaultMessage="Volume Open High Low Close"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('stockVOHLC')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/stockvohlc.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem
					cols={6}
					style={{
						height: '24px'
					}}
				>
					<div style={gridTitleStyle}>
						<FormattedMessage id="StreamChart.TypesFunnel" defaultMessage="Funnel Charts"/>
					</div>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.Funnel" defaultMessage="Funnel"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('funnel')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/funnel.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.FunnelBar" defaultMessage="Funnel Bar"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('funnelbar')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/funnelbar.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.HorizontalFunnel" defaultMessage="Horizontal Funnel"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('funnelhorizontal')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/funnelhorz.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.HorizontalFunnelColumn" defaultMessage="Horizontal Funnel Column"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('funnelhorizontalcolumn')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/funnelhorzcolumn.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem
					cols={6}
					style={{
						height: '24px'
					}}
				>
					<div style={gridTitleStyle}>
						<FormattedMessage id="StreamChart.TypesOther" defaultMessage="Other Charts"/>
					</div>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.Waterfall" defaultMessage="Waterfall Chart"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('waterfall')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/waterfall.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.BoxPlot" defaultMessage="Box Plot"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('boxplot')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/boxplot.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
				<ImageListItem cols={1}>
					<Tooltip
						enterDelay={300}
						title={<FormattedMessage id="StreamChart.Map" defaultMessage="Map"/>}
					>
						<IconButton
                            style={chartButtonStyle}
                            color="inherit"
                            onClick={() => this.props.onCreatePlot('map')}
                            size="large">
							<img alt="" style={chartImageStyle} src="images/charts/map.svg"/>
						</IconButton>
					</Tooltip>
				</ImageListItem>
			</ImageList>
        );
	}
}

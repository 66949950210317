import React from 'react';

export default [
	{
		selector: '[data-tour="step-nav-bar-apps"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Creating a new App</p>
				<p>To create an App navigate to the Apps Page.</p>
			</div>
		),
		routing: '/apps',
	},
	{
		selector: '[data-tour="apps-add"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Open New Dialog</p>
				<p>Now click on the &quot;+&quot; icon to open the New App Dialog.</p>
				<p>If you want to create from a template, you can also click on the template on the Home Page and you will be guided to the New App Dialog directly.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="new-dialog"]',
		hash: "#panel=/New/",
		position: 'left',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Create App Settings</p>
				<ol>
					<li>Define a name for your App.</li>
					<li>If needed, choose a template.</li>
					<li>In an environment where you have access to more than one workspace, also select the workspace in which the App should be created.</li>
				</ol>
			</div>
		),
	},
	{
		selector: '[data-tour="new-dialog-add"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Create App</p>
				<p>Click on Add to create the App.</p>
				<p>Then you can edit the App or continue with another tour.</p>
			</div>
		),
	},
];

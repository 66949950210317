import theme from '@cedalo/webui/src/theme';
import { DynamicFormattedMessage } from '@cedalo/webui/src/ui/utils/DynamicFormattedMessage';
import DeleteIcon from '@mui/icons-material/esm/Delete';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { withStyles } from '@mui/styles';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userShape } from './UserPropTypes';

const styles = {
	[theme.breakpoints.down('md')]: {
		hideOnMd: {
			display: 'none'
		}
	},
	[theme.breakpoints.down('lg')]: {
		hideOnLg: {
			display: 'none'
		}
	},
	cellContentContainer: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}
};

const USER_TABLE_COLUMNS = [
	{ id: 'username', key: 'Admin.User.username', width: '40%' },
	{ id: 'email', key: 'Admin.User.email', width: '60%' },
	{ id: 'firstName', key: 'Admin.User.firstName', hideOnMd: true, hideOnLg: true, width: '30%' },
	{ id: 'lastName', key: 'Admin.User.lastName', hideOnMd: true, hideOnLg: true, width: '30%' },
	{ id: 'type', key: 'Extensions.Admin.User.Type', hideOnMd: true, hideOnLg: true, width: '20%' },
	{ id: 'lastModified', key: 'Admin.User.lastModified', hideOnMd: true, width: '50%' }
];

const FormattedUserType = (props) => {
	switch (props.provider) {
		case 'local':
			return <FormattedMessage id="Extensions.Admin.User.Type.Local" defaultMessage="Local" />;
		default:
			return props.provider || '';
	}
};

export const UserTable = withStyles(styles, { withTheme: true })((props) => {
	const { users, onDeleteUser, onSelectUser, onSort, sortBy, sortDirection, classes, selectedUser } = props;

	return (
		<TableContainer sx={{ overflowY: 'auto' }}>
			<Table stickyHeader size="small" sx={{ marginTop: '25px', width: '100%', tableLayout: 'fixed' }}>
				<TableHead>
					<TableRow>
						{USER_TABLE_COLUMNS.map((column) => (
							<TableCell
								padding="none"
								key={column.id}
								sortDirection={sortBy === column.id ? sortDirection : false}
								style={{ width: column.width }}
								className={`${column.hideOnMd ? classes.hideOnMd : ''} ${
									column.hideOnLg ? classes.hideOnLg : ''
								}`}
							>
								<TableSortLabel
									active={sortBy === column.id}
									direction={sortDirection}
									onClick={() => onSort(column.id)}
								>
									<div className={classes.cellContentContainer}>
										<DynamicFormattedMessage id={column.key} defaultMessage={column.key} />
									</div>
								</TableSortLabel>
							</TableCell>
						))}
						<TableCell style={{ width: '20px' }} />
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map((user) => (
						<TableRow
							hover
							key={user.id}
							onClick={() => onSelectUser(user.id)}
							style={{
								cursor: 'pointer',
								height: '45px',
							}}
							selected={selectedUser === user.id}
						>
							<TableCell padding="none">
								<div className={classes.cellContentContainer}>
									<b>{user.username}</b>
								</div>
							</TableCell>
							<TableCell padding="none">
								<div className={classes.cellContentContainer}>{user.email}</div>
							</TableCell>
							<TableCell padding="none" className={`${classes.hideOnMd} ${classes.hideOnLg}`}>
								<div className={classes.cellContentContainer}>{user.firstName}</div>
							</TableCell>
							<TableCell padding="none" className={`${classes.hideOnMd} ${classes.hideOnLg}`}>
								<div className={classes.cellContentContainer}>{user.lastName}</div>
							</TableCell>
							<TableCell padding="none" className={`${classes.hideOnMd} ${classes.hideOnLg}`}>
								<FormattedUserType provider={user.provider} />
							</TableCell>
							<TableCell padding="none" className={classes.hideOnMd}>
								<div className={classes.cellContentContainer}>
									{formatDistanceToNow(new Date(user.lastModified), { addSuffix: true })}
								</div>
							</TableCell>
							<TableCell padding="none">
								{user.canDelete ? (
									<IconButton
										style={{ color: '#FF0022' }}
										onClick={(event) => {
											event.stopPropagation();
											onDeleteUser(user.id);
										}}
										size="large"
									>
										<DeleteIcon />
									</IconButton>
								) : null}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
});

UserTable.propTypes = {
	users: PropTypes.arrayOf(userShape).isRequired,
	sortBy: PropTypes.string,
	sortDirection: PropTypes.string,
	onDeleteUser: PropTypes.func.isRequired,
	onSelectUser: PropTypes.func.isRequired,
	onSort: PropTypes.func.isRequired
};

UserTable.defaultProps = {
	sortBy: undefined,
	sortDirection: undefined
};

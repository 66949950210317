import { HttpServerSettings } from './HttpServerSettings';
import SettingsIcon from '@mui/icons-material/esm/Settings';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const HttpServerPluginSettings = {
	id: 'HttpServerSettings',
	panel: (props) => <HttpServerSettings open {...props} />,
	// panel: (props) => <div>HALLO </div>,
	menuItemIcon: <SettingsIcon fontSize="small" />,
	menuItemText: <FormattedMessage id="AppActions.HTTPServer" defaultMessage="HTTP Server" />
};

import { OpcuaServerSettings } from './OpcuaServerSettings';
import SettingsIcon from '@mui/icons-material/esm/Settings';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const OpcuaServerPluginSettings = {
	id: 'OpcuaServerSettings',
	panel: (props) => <OpcuaServerSettings open {...props} />,
	// panel: (props) => <div>HALLO </div>,
	menuItemIcon: <SettingsIcon fontSize="small" />,
	menuItemText: <FormattedMessage id="AppActions.OPCUAServer" defaultMessage="OPCUA Server" />
};

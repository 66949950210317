import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import DialogButton from '@cedalo/webui/src/ui/utils/DialogButton';
// import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import { filterAndSort } from '@cedalo/webui/src/ui/utils/filterAndSort';
import theme from '@cedalo/webui/src/theme';
import { runQuery, useQuery, useSubscription } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import { DynamicFormattedMessage } from '@cedalo/webui/src/ui/utils/DynamicFormattedMessage';
import StreamSheetPageTitle from '@cedalo/webui/src/ui/utils/StreamSheetPageTitle';
import DeleteIcon from '@mui/icons-material/esm/Delete';
import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography
} from '@mui/material';
import { withStyles } from '@mui/styles';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const WORKSPACE_TABLE_COLUMNS = [
	{ id: 'name', key: 'Extensions.Admin.Workspace.Column.name', width: '100%' },
	{ id: 'userCount', key: 'Extensions.Admin.Workspace.Column.userCount', hideOnMd: true, width: '50%' },
	{ id: 'lastModified', key: 'Extensions.Admin.Workspace.Column.lastModified', hideOnMd: true, width: '100%' }
];

const styles = {
	[theme.breakpoints.down('lg')]: {
		hideOnMd: {
			display: 'none'
		}
	},
	cellContentContainer: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	}
};

const workspaceShape = PropTypes.shape({
	name: PropTypes.string,
	userCount: PropTypes.number,
	lastModified: PropTypes.string
});

const WorkspaceTable = withStyles(styles, { withTheme: true })(
	({ workspaces, onDelete, onSelect, onSort, sortBy, sortDirection, selectedWorkspace, classes }) => {
		return (
			<TableContainer sx={{ overflowY: 'auto' }}>
				<Table stickyHeader size="small" sx={{ marginTop: '25px', width: '100%', tableLayout: 'fixed' }}>
					<TableHead>
						<TableRow>
							{WORKSPACE_TABLE_COLUMNS.map((column) => (
								<TableCell
									padding="none"
									key={column.id}
									style={{ width: column.width }}
									sortDirection={sortBy === column.id ? sortDirection : false}
									className={column.hideOnMd ? classes.hideOnMd : ''}
								>
									<TableSortLabel
										active={sortBy === column.id}
										direction={sortDirection}
										onClick={() => onSort(column.id)}
									>
										<div className={classes.cellContentContainer}>
											<DynamicFormattedMessage id={column.key} defaultMessage={column.key} />
										</div>
									</TableSortLabel>
								</TableCell>
							))}
							<TableCell style={{ width: '20px' }} />
						</TableRow>
					</TableHead>
					<TableBody>
						{workspaces.map((workspace) => (
							<TableRow
								hover
								key={workspace.id}
								onClick={() => onSelect(workspace.id)}
								style={{
									cursor: 'pointer',
									height: '45px',
								}}
								selected={selectedWorkspace === workspace.id}
							>
								<TableCell>
									<div className={classes.cellContentContainer}>
										<b>{workspace.name}</b>
									</div>
								</TableCell>
								<TableCell padding="none" className={classes.hideOnMd}>
									<div className={classes.cellContentContainer}>{workspace.userCount}</div>
								</TableCell>
								<TableCell className={classes.hideOnMd}>
									<div className={classes.cellContentContainer}>
										{formatDistanceToNow(new Date(workspace.lastModified), { addSuffix: true })}
									</div>
								</TableCell>
								<TableCell padding="none">
									{workspace.canDelete ? (
										<IconButton
											style={{ color: '#FF0022' }}
											onClick={(event) => {
												event.stopPropagation();
												onDelete(workspace.id);
											}}
											size="large"
										>
											<DeleteIcon />
										</IconButton>
									) : null}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}
);

WorkspaceTable.propTypes = {
	workspaces: PropTypes.arrayOf(workspaceShape).isRequired,
	sortBy: PropTypes.string,
	sortDirection: PropTypes.string,
	onDelete: PropTypes.func.isRequired,
	onSelect: PropTypes.func.isRequired,
	onSort: PropTypes.func.isRequired
};

WorkspaceTable.defaultProps = {
	sortBy: undefined,
	sortDirection: undefined
};

const QUERY = `
{
	workspaces {
		id
		name
		lastModified
		userCount
		canDelete
	}
}
`;

const DELETE_WORKSPACE_MUTATION = `
mutation DeleteWorkspace($id: ID!) {
	deleteWorkspace(id: $id) {
		success
		code
	}
}
`;

const SUBSCRIPTION = `
subscription WorkspaceChanges {
	workspaceChanged {
		workspaceId
	  __typename
	  ... on WorkspaceCreateEvent {
		workspace {
			id
			name
			lastModified
			userCount
			canDelete
		}
	  }
	  ... on WorkspaceUpdateEvent {
		workspaceUpdate {
			id
			name
			lastModified
			userCount
			canDelete
		}
	  }
	}
  }
`;

const defaultState = {
	workspaces: undefined,
	errors: null,
	loading: true,
	workspaceId: null,
	pending: false,
	reloading: false
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'set_errors':
			return {
				...state,
				errors: action.data
			};
		case 'set_workspaces':
			return {
				...state,
				loading: false,
				workspaces: action.data
			};
		case 'update_workspace':
			return {
				...state,
				workspaces: state.workspaces.map((workspace) => (workspace.id === action.data.id ? action.data : workspace))
			};
		case 'add_workspace':
			return {
				...state,
				workspaces: [...state.workspaces, action.data]
			};
		case 'remove_workspace':
			return {
				...state,
				workspaces: state.workspaces.filter((workspace) => workspace.id !== action.data)
			};
		case 'set_workspace_to_delete':
			return {
				...state,
				workspaceToDelete: action.data,
				pending: false
			};
		case 'start_delete':
			return {
				...state,
				pending: true
			};
		case 'cancel_delete':
			return {
				...state,
				workspaceToDelete: null
			};
		case 'delete_success':
			return {
				...state,
				pending: false,
				reloading: true,
				workspaceToDelete: null
			};
		case 'done':
			return {
				...state,
				pending: false,
				reloading: false,
				workspaceToDelete: null
			};
		case 'reload_done':
			return {
				...state,
				reloading: false
			};
		default:
			throw new Error(`WorspaceTableView reducer received unknown action: ${action.type}`);
	}
};

const filterAndSortWorkspaces = filterAndSort(['name']);

export const WorkspaceTableView = ({ onSelectWorkspace, filterText, onDeleteWorkspace, selectedWorkspace }) => {
	const [sort, setSort] = useState({ field: 'name', direction: 'asc' });
	const [state, dispatch] = useReducer(reducer, defaultState);

	const workspaceQuery = useQuery(QUERY);

	useEffect(() => {
		if (workspaceQuery.status === 'error') {
			dispatch({ type: 'set_errors', errors: workspaceQuery.result });
		} else if (workspaceQuery.status === 'done') {
			dispatch({ type: 'set_workspaces', data: workspaceQuery.result.workspaces });
		}
	}, [workspaceQuery]);

	const [subData, subError] = useSubscription(SUBSCRIPTION);

	useEffect(() => {
		const data = subData && subData.workspaceChanged;
		if (data) {
			switch (data.__typename) {
				case 'WorkspaceUpdateEvent':
					dispatch({ type: 'update_workspace', data: data.workspaceUpdate });
					break;
				case 'WorkspaceCreateEvent':
					dispatch({ type: 'add_workspace', data: data.workspace });
					break;
				case 'WorkspaceDeleteEvent':
					dispatch({ type: 'remove_workspace', data: data.workspaceId });
					break;
				default:
			}
		}
	}, [subData]);
	useEffect(() => {
		if (subError) {
			console.error(subError);
			dispatch({ type: 'set_errors', errors: subError });
		}
	}, [subError]);

	if (state.errors) {
		return (
			<div
				style={{
					height: '100%',
					padding: '24px',
					backgroundColor: '#EEE',
					boxSizing: 'border-box',
					overflow: 'auto'
				}}
			>
				{state.errors.join('\n')}
			</div>
		);
	}

	const deleteWorkspace = async () => {
		onDeleteWorkspace(state.workspaceId);
		dispatch({ type: 'start_delete' });
		try {
			const {
				deleteWorkspace: { success, code }
			} = await runQuery(DELETE_WORKSPACE_MUTATION, {
				id: state.workspaceToDelete
			});
			if (success) {
				dispatch({ type: 'delete_success' });
			} else {
				dispatch({ type: 'done' });
				console.error(`Failed to delete workspaces: ${code}`);
			}
		} catch (error) {
			dispatch({ type: 'done' });
			console.error(error);
		}
	};

	const workspaces = filterAndSortWorkspaces(state.workspaces, filterText, sort);

	document.title = intl.formatMessage({ id: 'TabTitle.Workspaces' }, {});

	return (
		<Box
			sx={{
				height: 'calc(100% - 48px)',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<StreamSheetPageTitle
				title={<FormattedMessage id="WorkspacePage.Title" defaultMessage="Workspaces" />}
				subTitle={
					<FormattedMessage
						id="WorkspacePage.TitleSub"
						defaultMessage="List of all workspace. You can add, edit and delete workspaces..."
					/>
				}
			/>
			<WorkspaceTable
				workspaces={workspaces}
				selectedWorkspace={selectedWorkspace}
				onDelete={(workspaceId) => dispatch({ type: 'set_workspace_to_delete', data: workspaceId })}
				onSelect={(workspaceId) => onSelectWorkspace(workspaceId)}
				onSort={(field) => {
					const direction = field === sort.field && sort.direction === 'asc' ? 'desc' : 'asc';
					setSort({ field, direction });
				}}
				sortBy={sort.field}
				sortDirection={sort.direction}
			/>
			{!state.loading && workspaces.length === 0 && (
				<Box sx={{ textAlign: 'center' }}>
					<Typography color="text.primary"  variant="body2">
						<FormattedMessage
							id="Extensions.Admin.Workspace.noWorkspaceFound"
							defaultMessage="No Workspaces Found"
						/>
					</Typography>
				</Box>
			)}
			{state.loading && (
				<Grid container style={{ minHeight: '200px' }} justifyContent="center" alignItems="center">
					<Grid item>
						<CircularProgress />
					</Grid>
				</Grid>
			)}
			<Dialog open={!!state.workspaceToDelete && !state.pending && !state.deleted}>
				<DialogTitle>
					<FormattedMessage id="Extensions.Admin.WorkspaceDelete" defaultMessage="Delete Workspace" />
				</DialogTitle>
				<DialogContent
					style={{
						marginTop: '20px'
					}}
				>
					<DialogContentText>
						<FormattedMessage
							id="Extensions.Admin.Workspace.deleteWorkspaceMessage"
							defaultMessage="Please confirm to delete this workspace."
						/>
					</DialogContentText>
				</DialogContent>
				<Divider />
				<DialogActions>
					<DialogButton onClick={() => dispatch({ type: 'cancel_delete' })} margin>
						<FormattedMessage id="Dialog.Cancel" defaultMessage="Cancel" />
					</DialogButton>
					<DialogButton data-action="delete" onClick={() => deleteWorkspace()} margin autoFocus>
						<FormattedMessage id="Dialog.Delete" defaultMessage="Delete" />
					</DialogButton>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

WorkspaceTableView.propTypes = {
	filterText: PropTypes.string.isRequired,
	onSelectWorkspace: PropTypes.func.isRequired,
	onDeleteWorkspace: PropTypes.func.isRequired
};

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { StatusDot } from '@cedalo/webui/src/ui/sheeteditor/connections/StatusDot';
import TEST_ACCOUNT_MUTATION from './test-account.gql';
// import TEST_ACCOUNT_MUTATION from './test-account.gql';
import { runQuery } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import { Box, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
	hidden: {
		display: 'none'
	}
}));

const RESET_DELAY_MS = 5000;

const ButtonText = ({ status }) => {
	switch (status) {
		case 'unknown':
			return <FormattedMessage id="Extensions.TestAccount" defaultMessage="Test" />;
		case 'connected':
			return <FormattedMessage id="Extensions.TestAccount.Connected" defaultMessage="Connected" />;
		case 'disconnected':
			return <FormattedMessage id="Extensions.TestAccount.Disconnected" defaultMessage="Disonnected" />;
		default:
			return '';
	}
};

export const TestConnection = ({ accountId, resetIndicator, onError = () => {} }) => {
	const [status, setStatus] = useState('unknown');
	const classes = useStyles();
	const [hovering, setHovering] = useState(false);

	useEffect(() => {
		setStatus('unknown');
		onError(null);
	}, [resetIndicator, accountId]);

	useEffect(() => {
		if (status === 'connected') {
			const timeoutId = setTimeout(() => {
				setStatus('unknown');
			}, RESET_DELAY_MS);
			return () => clearTimeout(timeoutId);
		}
		return () => {}
	}, [status]);

	const testConnection = async () => {
		try {
			setStatus('pending');
			const { testAccount } = await runQuery(TEST_ACCOUNT_MUTATION, { accountId });
			setStatus(testAccount.connected ? 'connected' : 'disconnected');
			onError(testAccount.error);
		} catch (e) {
			setStatus('disconnected');
			onError(JSON.stringify(e));
		}
	};

	const showButtonText = hovering || status === 'connected' || status === 'disconnected';

	return (
		<Box>
			<Button
				variant="text"
				style={{ justifyContent: 'flex-start', padding: '3px 4px' }}
				onClick={testConnection}
				onFocus={() => setHovering(true)}
				onBlur={() => setHovering(false)}
				onMouseOver={() => setHovering(true)}
				onMouseOut={() => setHovering(false)}
			>
				<StatusDot
					status={status}
					style={{
						cursor: 'pointer'
					}}
				/>
				<Typography lineHeight="normal" className={showButtonText ? null : classes.hidden}>
					<ButtonText status={status} />
				</Typography>
			</Button>
		</Box>
	);
};

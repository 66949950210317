import { MachineDataPanel } from './MachineDataDownloader';
import Icon from '@mui/icons-material/esm/Folder';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const MachineDataPluginSettings = {
	id: 'Files',
	panel: (props) => <MachineDataPanel open {...props} />,
	panelSize: 600,
	menuItemIcon: <Icon fontSize="small" />,
	menuItemText: <FormattedMessage id="AppActions.AppFiles" defaultMessage="App Files" />
};

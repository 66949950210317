import { EnsureUser } from '@cedalo/webui/src/UserProvider';
import React, { useEffect } from 'react';
import { Route, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AccountPage } from './accounts';
import { IntegrationsPage } from './integrations';
import SharedMachinePage from './sharemachine/SharedMachinePage';
import { WorkspacePage } from './workspace';

const Redirect = (props) => {
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	useEffect(() => {
		navigate(props.path({ location, params }));
	}, []);
	return null;
};

export function getExtensionRoutes() {
	return (
		<React.Fragment>
			<Route
				path="/shared-machine/:linkId"
				element={<Redirect path={({ location }) => location.pathname.replace('shared-machine', 'a')} />}
			/>
			<Route path="/share/:linkId" element={<SharedMachinePage />} />,
			<Route path="accounts">
				<Route
					index
					element={
						<EnsureUser>
							<AccountPage />
						</EnsureUser>
					}
				/>
				<Route
					path=":accountId"
					element={
						<EnsureUser>
							<AccountPage />
						</EnsureUser>
					}
				/>
			</Route>
			<Route path="workspaces">
				<Route
					index
					element={
						<EnsureUser>
							<WorkspacePage />
						</EnsureUser>
					}
				/>
				<Route
					path=":workspaceId"
					element={
						<EnsureUser>
							<WorkspacePage />
						</EnsureUser>
					}
				/>
			</Route>
			<Route path="integrations">
				<Route
					index
					element={
						<EnsureUser>
							<IntegrationsPage />
						</EnsureUser>
					}
				/>
				<Route
					path=":integrationId"
					element={
						<EnsureUser>
							<IntegrationsPage />
						</EnsureUser>
					}
				/>
			</Route>
		</React.Fragment>
	);
}

import React from 'react';
import { styled } from '@mui/material/styles';

const StatusDotBase = styled((props: React.HTMLAttributes<HTMLDivElement>) => (
	<div
		{...props}
		style={{
			position: 'relative',
			borderRadius: '8px',
			height: '16px',
			width: '16px',
			// backgroundColor: 'rgba(255, 0, 0, 0.4)',
			display: 'inline-flex',
			justifyContent: 'center',
			alignItems: 'center',
			margin: '5px',
			...props.style
		}}
	/>
));

const StatusDotDisonnected = StatusDotBase(() => ({
	backgroundColor: '#EEAAAA',
	':after': {
		position: 'absolute',
		width: '8px',
		height: '8px',

		borderRadius: '50%',
		animation: 'pulse 1s infinite 1.5s ease-in',
		backgroundColor: '#BB3333',
		content: '""'
	},
	'@keyframes pulse': {
		'0%': {
			transform: 'scale(1)'
		},
		'50%': {
			transform: 'scale(1.1)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	}
}));

const StatusDotPending = StatusDotBase(() => ({
	backgroundColor: '#AAAAEE',
	':after': {
		position: 'absolute',
		width: '8px',
		height: '8px',
		borderRadius: '50%',
		animation: 'pulse 0.7s infinite ease-in-out',
		backgroundColor: '#3333BB',
		content: '""'
	},
	'@keyframes pulse': {
		'0%': {
			transform: 'scale(1)'
		},
		'50%': {
			transform: 'scale(1.2)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	}
}));

const StatusDotUnknown = StatusDotBase(() => ({
	backgroundColor: '#CCC',
	':after': {
		position: 'absolute',
		width: '10px',
		height: '10px',
		borderRadius: '50%',
		// animation: 'pulse 1.5s infinite cubic-bezier(0.22, 0.61, 0.36, 1)',
		backgroundColor: '#AAA',
		content: '""'
	}
	// '@keyframes pulse': {
	// 	'0%': {
	// 		transform: 'scale(1)'
	// 	},
	// 	'50%': {
	// 		transform: 'scale(1.1)'
	// 	},
	// 	'100%': {
	// 		transform: 'scale(1)'
	// 	}
	// }
}));

const StatusDotConnected = StatusDotBase(() => ({
	backgroundColor: '#AAEEAA',
	':after': {
		position: 'absolute',
		width: '10px',
		height: '10px',
		borderRadius: '50%',
		animation: 'pulse 1.5s infinite cubic-bezier(0.22, 0.61, 0.36, 1)',
		backgroundColor: '#33BB33',
		content: '""'
	},
	'@keyframes pulse': {
		'0%': {
			transform: 'scale(1)'
		},
		'50%': {
			transform: 'scale(1.1)'
		},
		'100%': {
			transform: 'scale(1)'
		}
	}
}));

export type StatusDotState = 'connected' | 'disconnected' | 'pending' | 'unknown';

export interface StatuDotProps extends React.HTMLAttributes<HTMLDivElement> {
	status: StatusDotState;
}

function assertUnreachable(state: never): never;
function assertUnreachable(state: StatusDotState): never {
	throw new Error(`unknown StatusDot state type ${state}`);
}

export const StatusDot = ({ status = 'unknown', ...props }: StatuDotProps) => {
	switch (status) {
		case 'connected':
			return <StatusDotConnected {...props} />;
		case 'disconnected':
			return <StatusDotDisonnected {...props} />;
		case 'pending':
			return <StatusDotPending {...props} />;
		case 'unknown':
			return <StatusDotUnknown {...props} />;
		default:
			assertUnreachable(status);
	}
};

// import {AppPage} from '@cedalo/webui/src/ui/pages/AppPage';
import SheetComponent from '@cedalo/webui/src/ui/sheeteditor/SheetComponent';
import {useJSGNotification} from '@cedalo/webui/src/ui/utils/JSGHooks';
import {clearSidePanelHash, parseSidePanelHash, setSidePanelHash} from '@cedalo/webui/src/ui/utils/SidePanel';
import {Box} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setAppState } from '@cedalo/webui/src/actions/actions';
import { pathLogin, pathLogout } from '@cedalo/webui/src/actions/UserActions';
import gatewayClient from '@cedalo/webui/src/helper/GatewayClient';
import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import {getPluginSettingsComponent} from '../PluginSettings';
import ErrorPage from './ErrorPage';
import ConfigManager from '@cedalo/webui/src/helper/ConfigManager';
import { accessManager } from '@cedalo/webui/src/helper/AccessManager';
import { EnsureUser } from '@cedalo/webui/src/UserProvider';
import { AppProvider } from '@cedalo/webui/src/ui/app/AppProvider';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

const CONFIG = ConfigManager.config.gatewayClientConfig;

const SHARED_MACHINE = `
query SharedMachine($id: ID!) {
	sharedMachine(id: $id) {
		code,
		success,
		link {
			machineId
		}
	}
}
`;

const SharedPageWrapper = (props) => {
	const navigate = useNavigate();
	const [sidePanel, setSidePanel] = useState(() => {
		const id = parseSidePanelHash(location.hash);
		return id;
	});

	const onShowDialog  = (name) => {
		const info = {
			id: name,
		}
		setSidePanel(info);
		setSidePanelHash(navigate, info.id, info.data);
	};

	const getSidePanel = () => {
		switch (sidePanel.id) {
		default: {
			const PluginSettings = getPluginSettingsComponent(sidePanel.id);
			return PluginSettings ? <PluginSettings open onClose={() => clearSidePanelHash(navigate)} /> : null;
		}
		}
	};

	useEffect(() => {
		const hash = parseSidePanelHash(location.hash);
		if (hash.id === null) {
			const info = {}
			setSidePanel(info);
			setSidePanelHash(navigate, info.id, info.data);
		}
	}, [location.hash]);

	useJSGNotification('showDialog:File', () => onShowDialog('Files'));

	return (
		<AppProvider {...props} machineId={props.machineId} noEdit>
			<Box
				sx={{
					height: `100%`,
					position: 'relative',
					width: '100%',
					display: 'inline-flex',
					backgroundColor: (theme) => theme.wall.backgroundColor
				}}
			>
				<div
					style={{
						height: `100%`,
						width: `calc(100% - ${sidePanel.id ? 600 : 0}px)`,
						position: 'relative',
					}}
				>
					<SheetComponent
						appId={props.machineId}
						splitView={false}
						mediaWidth="large"
						showViewMode
						// showDialog={(id) => onShowDialog(id)}
						// onSheetActivate={(id) => setActiveSheetId(id)}
						canEditMachine={false}
					/>
				</div>
				{getSidePanel()}
			</Box>
			{/* <AppPage {...props} /> */}
		</AppProvider>
	);
};

const SharedMachineError = {
	from: (code) => {
		let message;
		let title;
		switch (code) {
			case 'LINK_EXPIRED':
			case 'LINK_INVALID':
			case 'LINK_NOT_AVAILABLE':
			case 'MACHINE_NOT_AVAILABLE':
				title = '404';
				message = intl.formatMessage({ id: 'Extensions.SharedMachinePage.Error.LinkInvalid' });
				break;
			default:
				message = 'Login failed! Link is invalid.';
		}
		console.log(SharedMachineError.create(message, { title }));
		return SharedMachineError.create(message, { title });
	},
	create: (message, other) => Object.assign(new Error(message), other)
};

const SharedMachinePage = (props) => {
	const location = useLocation();
	const { linkId } = useParams();
	const [error, setError] = useState();
	const [machineId, setMachineId] = useState('');

	const setup = async () => {
		const alreadyLoggedIn = !!accessManager.authToken;
		let connected = false;
		try {
			if (alreadyLoggedIn) {
				await gatewayClient.connect(CONFIG);
				connected = true;
			} else {
				const resp = await props.pathLogin(location.pathname);
				if (resp.error && resp.error === 'LOGIN_REQUIRED') {
					window.location.href = `${window.location.origin}/login?redirect=${encodeURIComponent(
						location.pathname + location.search + location.hash
					)}`;
					return;
				}
				connected = !resp.error && !!resp.token;
			}
			if (!connected) throw SharedMachineError.from('LINK_INVALID');
			const { sharedMachine } = await gatewayClient.graphql(SHARED_MACHINE, { id: linkId });
			const { success, code, link } = sharedMachine;
			if (!success) throw SharedMachineError.from(code);
			setMachineId(link.machineId);
		} catch (err) {
			setError(err);
			// ensure we are connected or calling logout fails due to missing socket connection...
			if (connected && !alreadyLoggedIn) props.pathLogout();
		}
	};
	useEffect(() => {
		setup();
	}, []);

	// eslint-disable-next-line no-nested-ternary
	return machineId ? (
		<EnsureUser>
			<SharedPageWrapper {...props} machineId={machineId} />
		</EnsureUser>
	) : error ? (
		<ErrorPage title={error.title} message={error.message} />
	) : null;
};

const mapDispatchToProps = { pathLogin, pathLogout, setAppState };

export default connect(undefined, mapDispatchToProps)(SharedMachinePage);

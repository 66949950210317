import { withUser } from '../../UserProvider';
import { withAppData } from '../app/AppProvider';

export const withScopeRights = (component) =>
	withAppData(({ machine }) => ({ scopeId: machine.scope.id }))(
		withUser(({ user }, { scopeId }) => {
			const scope = user.scopes.find(({ id }) => id === scopeId);
			return {
				rights: scope ? scope.rights : []
			};
		})(component)
	);

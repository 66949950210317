import React from 'react';

export default [
	{
		selector: '[data-tour="page-app"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					This tour will support you in creating your first functioning App creating a payload and using a MQTT connection to subscribe and publish to a certain topic.
					MQTT is a transport protocol, which is used to create data streams. One client can publish data to a broker using a topic.
					Many other clients can now subscribe to this topic and will all get the newest publish right away.
				</p>
				<p>
					We will set up a connection to a so-called MQTT Broker, define a payload to be sent, and then publish this message.
					As the next step, we will then subscribe to the topic, we are publishing to.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		range: "Sheet1!A1:B2",
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					First, let us start by creating a data simulation. Insert parameter names for your future payload into A1 and A2 (E.g. &quot;Parameter1&quot; and &quot;Parameter2&quot;).
				</p>
				<p>
					Go into B1 and B2 and create random data by using the =RANDBETWEEN(1,100) function. This gives a random number on every calculation between 1 and 100.
				</p>
				<p>
					Select the range from A1 to B2, mouse right click and select &quot;Define JSON Range&quot;.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="button-connections"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Now that we have our data simulation, we have to set up the connection to our Broker.
					Streamsheets comes with an integrated MQTT broker, if you don´t have one at hand.
					Click the connection button and add a MQTT Connection by clicking on the &quot;+&quot; icon and selecting MQTT.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="settings-sidepanel"]',
		hash: 'panel=/Connections/',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Leave the default name to &quot;MQTT_Connection&quot;. The connection details can be either set directly in a connection,
					or you use an already created Account with the stored details. This is what we will do. The Internal MQTT Broker has a
					preconfigured account called &quot;Internal Mosquitto&quot;. Choose that one and the connection will show a green light at the top.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		range: "Sheet1!A6",
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Back on the grid, we are now able to set up a MQTT publish.
					Go to A6 and enter =MQTT.PUBLISH(MQTT_Connection!, A1:B2,&quot;example/topic&quot;)
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="app-control"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Now start the App. You can see, that the number values change. The MQTT.PUBLISH() sends a message with your
					defined payload to the topic &quot;example/topic&quot;.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		hash: '',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					Now let us receive the published data within another Streamsheet. Go and create a new sheet by clicking on the plus button below the sheet.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="button-inboxsettings"]',
		hash: 'panel=/InboxSettings/',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>We now will connect the inbox to the MQTT connection. Open the Inbox settings.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="settings-sidepanel"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Go to the Tab &quot;Connections&quot;. Click on the &quot;+&quot; next to &quot;MQTT_Connection&quot; and enter the topic we used for our publish (example/topic)</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					Now data should arrive in the Inbox.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>
					One more thing before we are done. Go back to the Inbox settings tab and change the calculation
					mode from &quot;On Interval&quot; to &quot;On message arrival&quot;. So the sheet now triggers only when a new message arrives and not in a set interval.
				</p>
			</div>
		),
	},
	{
		selector: '[data-tour="page-app"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p>Last but not least, drag and drop the payload from the inbox on the sheet to connect the inbox messages to calculations on the sheet.</p>
			</div>
		),
	},
];

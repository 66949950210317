import React from 'react';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';

const styles = {
	root: {
		display: 'flex',
		justifyContent: 'center',
		height: '100%',
		width: '100%'
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'space-around'
	},
	item: {
		padding: '1.2em'
	}
};

export default function ErrorPage({ title = 'Error', message = 'An unexpected error occurred.' }) {
	return (
		<div style={styles.root}>
			<div style={styles.container}>
				<div style={styles.item}>
					<Typography variant="h2" color="primary" noWrap style={{ fontWeight: 'bolder' }}>
						{title}
					</Typography>
				</div>
				<div style={styles.item}>
					<Typography variant="h4" color="textSecondary" noWrap>
						{message}
					</Typography>
				</div>
				<div style={{ ...styles.item, paddingTop: '3em' }}>
					<Typography variant="h5" color="textSecondary" noWrap>
						<FormattedMessage
							id="Extensions.ErrorPage.Hint"
							defaultMessage="Please contact your administrator to resolve this issue."
						/>
					</Typography>
				</div>
			</div>
		</div>
	);
}

import React from 'react';

export default [
	{
		selector: '[data-tour="page-home"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Welcome to our Streamsheets Beginner Tour!</p>
				<p>In this tour we will briefly explain the Streamsheets UI, basic navigations and create your first App.</p>
				<p>Let&apos;s start with the Streamsheets Homepage and different navigation menus.</p>
			</div>
		),
		routing: '/home',
	},
	{
		selector: '[data-tour="home-favorites"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Favorites</p>
				<p>Set certain Apps as your Favorites and make them appear in this section of the homepage.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="home-learn"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Learn</p>
				<p>Besides this App tour and the help within the platform, we have plenty more supportive content for you, e.g. the documentation and tutorials.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="home-templates"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Templates</p>
				<p>Sometimes it is best to start with a ready-made app. Template Apps  describe certain use cases, explain Streamsheets mechanics, and are possibly already the solution you are looking for!</p>
			</div>
		),
	},
	{
		selector: '[data-tour="home-news"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>News</p>
				<p>The latest news and blog posts around Streamsheets, Mosquitto, and Cedalo.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="step-nav-bar-home"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Home</p>
				<p>Let&apos;s look at the navigation bar on the left, before we build our first application. This bar serves as quick access to the main elements you will be working with.
					Currently, we are on the &quot;Home&quot; screen.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="step-nav-bar-apps"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>List of Apps</p>
				<p>List of Apps is an overview of all the applications you have access to. They can be divided into workspaces.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="step-nav-bar-accounts"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Accounts</p>
				<p>To connect Streamsheets apps with each other, to IoT things, databases or other services, you create &quot;Connections&quot; within each app. Thanks to &quot;Accounts&quot; you can manage shared information, such as credentials, in a central place and assign user rights.
					Accounts can be created for MQTT, Websockets, MongoDB and OPC UA.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="step-nav-bar-integrations"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Integration Accounts</p>
				<p>Integration Accounts are similar to Accounts but instead of focusing on communication protocols, they store credentials and configurations for certain APIs like Stripe, Binance and many more.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="step-nav-bar-export"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Export</p>
				<p>Exporting and Importing makes it easy to copy an App, backup some of your work or share it with other Streamsheets instances.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="step-nav-bar-admin"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Administration</p>
				<p>The administrative menus are in the bottom-left corner. Here you can manage users, workspaces, and the general settings.</p>
			</div>
		),
	},
	{
		selector: '[data-tour="button-tour"]',
		content: () => (
			<div style={{fontSize: '10pt'}}>
				<p style={{fontSize: '13pt'}}>Tour</p>
				<p>Select another tour to continue.</p>
			</div>
		),
	},
];

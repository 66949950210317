import qs from 'query-string';
import { useState, useEffect } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';

export const toHashObject = (hashString) => {
	const parseAbleHash = hashString ? hashString.substring(1) : '';
	return qs.parse(parseAbleHash);
};

export const toHashString = (hashObject) =>
	hashObject && Object.keys(hashObject).length ? `#${qs.stringify(hashObject, { encode: false })}` : '';

export const getHash = () => toHashObject(window.location.hash);

export const setHash = (navigate: NavigateFunction, object: object) => {
	const hashObject = toHashObject(window.location.hash);
	const newHashObject = {
		...hashObject,
		...object
	};
	navigate(toHashString(newHashObject), { replace: true });
};

export const clearHashProperty = (navigate: NavigateFunction, name: string) => {
	const { [name]: current, ...newHashObject } = toHashObject(window.location.hash);
	if (current) {
		navigate(toHashString(newHashObject), { replace: true });
	}
};

export const useHashProperty = (name, initialValue, { clear = false }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [value, setValue] = useState(initialValue || toHashObject(location.hash)[name]);
	const [initialized, setInitialized] = useState(false);

	useEffect(
		() => () => {
			if (clear) {
				clearHashProperty(navigate, name);
			}
		},
		[]
	);

	useEffect(() => {
		if (initialized) {
			const newValue = toHashObject(location.hash)[name];
			setValue(newValue);
		} else {
			if (value) {
				setHash(navigate, { [name]: value });
			}
			setInitialized(true);
		}
	}, [location.hash]);

	return [
		value,
		(newValue) => {
			setValue(newValue);
			setHash(navigate, { [name]: newValue });
		}
	];
};

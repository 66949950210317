import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { injectIntl } from 'react-intl';
import Popover from '@mui/material/Popover';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ArrowDropDown from '@mui/icons-material/esm/ArrowDropDown';

class TableSortHeader extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null
		};
	}

	createSortHandler = (property) => (event) => {
		this.props.onRequestSort(event, property);
	};

	handleClick = (event) => {
		this.setState({
			anchorEl: event.currentTarget
		});
	};

	handleToggle = (field, checked) => {
		this.props.onFieldToggle(field, checked);
	};

	handleClose = () => {
		// this.props.onUpdateSelected(this.state.selectedOptions, this.state.dirty);
		// isSet = true;
		this.setState({
			anchorEl: null,
		});
	};


	render() {
		const { order, orderBy } = this.props;

		return (
			<TableHead
				data-tour="apps-sort"
			>
				<TableRow
					style={{
						height: `${this.props.height}px`
					}}
				>
					{this.props.cells.map(
						(row) =>
							row.component ? (
								row.component
							) : (
								<TableCell
									sx={{
										width: row.width,
										minWidth: row.minWidth,
										display: row.hideOnSmallDisplays ? {
											xs: 'none',
											sm: 'none',
											md: row.hideOnSmallDisplays === 'lg' ? 'table-cell' : 'none',
											lg: 'table-cell',
											xl: 'table-cell'
										} : undefined,
										backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#212121' : '#FFFFFF',
									}}
									padding={row.padding ? 'default' : 'none'}
									key={row.id}
									align={row.numeric ? 'right' : 'left'}
									sortDirection={orderBy === row.id ? order : false}
								>
									{/* eslint-disable-next-line no-nested-ternary */}
									{row.sort !== false ? (
										<TableSortLabel
											key="label"
											active={orderBy === row.id}
											direction={order}
											onClick={this.createSortHandler(row.id)}
										>
											{row.label.length
												? this.props.intl.formatMessage({
														id: row.label,
														defaultMessage: 'title'
												  })
												: ''}
										</TableSortLabel>
									) : row.label ? (
										this.props.intl.formatMessage({
											id: row.label,
											defaultMessage: 'title'
										})
									) : (
										''
									)}
									{row.fields ? (
										<IconButton key="btn" style={{ padding: '0px' }} size="small" onClick={this.handleClick}>
											<ArrowDropDown />
										</IconButton>
									) : null}
									{row.fields ? (
										<Popover
											open={Boolean(this.state.anchorEl)}
											anchorEl={this.state.anchorEl}
											onClose={this.handleClose}
											anchorOrigin={{
												vertical: 'bottom',
												horizontal: 'left'
											}}
											transformOrigin={{
												vertical: 'top',
												horizontal: 'left'
											}}
										>
											<List dense>
												<div style={{ maxHeight: '250px', overflowY: 'scroll' }}>
													{row.fields.map((field) => (
														<ListItem
															key={field.name}
															style={{ padding: '0px' }}
															dense
															button
															onClick={(event) =>
																this.handleToggle(field, event.target.checked)
															}
														>
															<Checkbox
																checked={field.selected}
																tabIndex={-1}
																disableRipple
															/>
															<ListItemText
																primary={
																	field.name === 'Admin.#all_provs'
																		? this.props.intl.formatMessage({
																				id: field.name,
																				defaultMessage: field.name
																		  })
																		: field.name
																}
															/>
														</ListItem>
													))}
												</div>
											</List>
										</Popover>
									) : null}
								</TableCell>
							),
						this
					)}
				</TableRow>
			</TableHead>
		);
	}
}

TableSortHeader.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	cells: PropTypes.array.isRequired,
	orderBy: PropTypes.string.isRequired,
	height: PropTypes.number
};

TableSortHeader.defaultProps = {
	height: 25
};

export default injectIntl(TableSortHeader);

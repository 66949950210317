import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import DialogButton from '@cedalo/webui/src/ui/utils/DialogButton';
import { runQuery, useQuery, useSubscription } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import StreamSheetPageTitle from '@cedalo/webui/src/ui/utils/StreamSheetPageTitle';
import { Box, Divider } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { UserTable } from './UserTable';

const QUERY = `
{
	users {
		id
		username
		canDelete
		email
		lastName
		firstName
		lastModified
		provider
	}
}
`;
const SUBSCRIPTION = `
subscription UserChanges {
	userChanged {
	  userId
	  __typename
	  ... on UserCreateEvent {
		user {
		  id
		  username
		  canDelete
		  email
		  lastName
		  firstName
		  lastModified
		  provider
		}
	  }
	  ... on UserUpdateEvent {
		userUpdate {
		  id
		  username
		  canDelete
		  email
		  lastName
		  firstName
		  lastModified
		  provider
		}
	  }
	}
  }
  
`;

const DELETE_USER_MUTATION = `
	mutation CreateUser($id: ID!) {
		deleteUser(id: $id) {
			success
			code
		}
	}
`;

const defaultState = {
	users: null,
	errors: null,
	loading: true,
	userId: null,
	pending: false,
	reloading: false
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'set_errors':
			return {
				...state,
				errors: action.data
			};
		case 'set_users':
			return {
				...state,
				loading: false,
				users: action.data
			};
		case 'update_user':
			return {
				...state,
				users: state.users.map((user) => (user.id === action.data.id ? action.data : user))
			};
		case 'add_user':
			return {
				...state,
				users: [...state.users, action.data]
			};
		case 'remove_user':
			return {
				...state,
				users: state.users.filter((user) => user.id !== action.data)
			};
		case 'set_user_id':
			return {
				...state,
				userId: action.data,
				pending: false
			};
		case 'start_delete':
			return {
				...state,
				pending: true
			};
		case 'cancel_delete':
			return {
				...state,
				userId: null
			};
		case 'delete_success':
			return {
				...state,
				pending: false,
				reloading: true,
				userId: null
			};
		case 'reload_done':
			return {
				...state,
				reloading: false
			};
		default:
			throw new Error('UserTablePage reducer received unknown action', action.type);
	}
};

export const UserTableView = ({ onSelectUser, filterText, onDeleteUser, selectedUser }) => {
	const [sort, setSort] = useState({ field: 'username', direction: 'asc' });
	const [state, dispatch] = useReducer(reducer, defaultState);

	const userQuery = useQuery(QUERY);

	useEffect(() => {
		if (userQuery.status === 'error') {
			dispatch({ type: 'set_errors', errors: userQuery.result });
		} else if (userQuery.status === 'done') {
			dispatch({ type: 'set_users', data: userQuery.result.users });
		}
	}, [userQuery]);

	const [subData, subError] = useSubscription(SUBSCRIPTION);

	useEffect(() => {
		const data = subData && subData.userChanged;
		if (data) {
			switch (data.__typename) {
				case 'UserUpdateEvent':
					dispatch({ type: 'update_user', data: data.userUpdate });
					break;
				case 'UserCreateEvent':
					dispatch({ type: 'add_user', data: data.user });
					break;
				case 'UserDeleteEvent':
					dispatch({ type: 'remove_user', data: data.userId });
					break;
				default:
			}
		}
	}, [subData]);
	useEffect(() => {
		if (subError) {
			console.error(subError);
			dispatch({ type: 'set_errors', errors: subError });
		}
	}, [subError]);

	const directionMultiplier = sort.direction === 'asc' ? 1 : -1;

	if (state.errors) {
		return (
			<div
				style={{
					height: '100%',
					padding: '24px',
					backgroundColor: '#EEE',
					boxSizing: 'border-box',
					overflow: 'auto'
				}}
			>
				{state.errors.join('\n')}
			</div>
		);
	}

	const deleteUser = async () => {
		onDeleteUser(state.userId);
		dispatch({ type: 'start_delete' });
		try {
			const {
				deleteUser: { success, code }
			} = await runQuery(DELETE_USER_MUTATION, {
				id: state.userId
			});
			if (success) {
				dispatch({ type: 'delete_success' });
			} else {
				dispatch({ type: 'done' });
				console.error(`Failed to delete user: ${code}`);
			}
		} catch (error) {
			dispatch({ type: 'done' });
			console.error(error);
		}
	};

	const filterAndSort = () => {
		if (state.loading) {
			return [];
		}
		const users = state.users || [];
		const shownUsers = filterText
			? users.filter(({ username, email, firstName, lastName }) =>
					Object.values({ username, email, firstName, lastName })
						.join('\n')
						.toLowerCase()
						.match(filterText.toLowerCase())
			  )
			: users;
		const sortedUsers = shownUsers.sort(
			(a, b) =>
				((a[sort.field] || '').toLowerCase() > (b[sort.field] || '').toLowerCase() ? 1 : -1) *
				directionMultiplier
		);
		return sortedUsers;
	};

	const users = filterAndSort();

	document.title = intl.formatMessage({ id: 'TabTitle.Users' }, {});

	return (
		<Box
			sx={{
				height: 'calc(100% - 48px)',
				display: 'flex',
				flexDirection: 'column'
			}}
		>
			<StreamSheetPageTitle
				title={<FormattedMessage id="Admin.Users" defaultMessage="Users" />}
				subTitle={<FormattedMessage id="Admin.Users.Sub" defaultMessage="Create and edit users..." />}
			/>
			<UserTable
				users={users}
				selectedUser={selectedUser}
				onDeleteUser={(userId) => dispatch({ type: 'set_user_id', data: userId })}
				onSelectUser={(userId) => onSelectUser(userId)}
				onSort={(field) => {
					const direction = field === sort.field && sort.direction === 'asc' ? 'desc' : 'asc';
					setSort({ field, direction });
				}}
				sortBy={sort.field}
				sortDirection={sort.direction}
				loading={state.loading}
			/>
			{!state.loading && users.length === 0 && (
				<Box sx={{ textAlign: 'center' }}>
					<Typography color="text.primary" variant="body2">No Users Found</Typography>
				</Box>
			)}
			{state.loading && (
				<Grid container style={{ minHeight: '200px' }} justifyContent="center" alignItems="center">
					<Grid item>
						<CircularProgress />
					</Grid>
				</Grid>
			)}
			{/* {state.pending && (
				<Overlay>
					<CircularProgress style={{ width: '24px', height: '24px' }} />
				</Overlay>
			)}
			{state.reloading && (
				<Overlay>
					<CheckIcon color="primary" />
				</Overlay>
			)} */}
			<Dialog open={!!state.userId && !state.pending && !state.deleted}>
				<DialogTitle>
					<FormattedMessage id="Admin.userDelete" defaultMessage="Delete User" />
				</DialogTitle>
				<DialogContent
					style={{
						marginTop: '20px'
					}}
				>
					<DialogContentText>
						<FormattedMessage
							id="Admin.deleteUserMessage"
							defaultMessage="Please confirm to delete this user."
						/>
					</DialogContentText>
				</DialogContent>
				<Divider />
				<DialogActions>
					<DialogButton onClick={() => dispatch({ type: 'cancel_delete' })} margin>
						<FormattedMessage id="Dialog.Cancel" defaultMessage="Cancel" />
					</DialogButton>
					<DialogButton data-action="delete" onClick={() => deleteUser()} autoFocus margin>
						<FormattedMessage id="Dialog.Delete" defaultMessage="Delete" />
					</DialogButton>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

UserTableView.propTypes = {
	filterText: PropTypes.string.isRequired,
	onSelectUser: PropTypes.func.isRequired,
	onDeleteUser: PropTypes.func.isRequired
};

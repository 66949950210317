import { IconUser } from '@cedalo/webui/src/components/icons';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userShape } from './UserPropTypes';

export const UpdatePasswordForm = (props) => {
	const {
		user,
		disabled,
		valid,
		errors,
		intl,
		requireOldPassword,
		oldPassword,
		onOldPasswordUpdate,
		newPassword,
		passwordConfirmation,
		onNewPasswordUpdate,
		onPasswordConfirmationUpdate,
		onSubmit,
		onCancel
	} = props;

	const errorsMappings = {
		EMAIL_INVALID: intl.formatMessage({ id: 'Admin.User.errorEMailInvalid' }, {}),
		EMAIL_IN_USE: intl.formatMessage({ id: 'Admin.User.errorEMailInUse' }, {}),
		EMAIL_REQUIRED: intl.formatMessage({ id: 'Admin.User.errorEMailRequired' }, {}),
		USERNAME_REQUIRED: intl.formatMessage({ id: 'Admin.User.errorUsernameRequired' }, {}),
		USERNAME_IN_USE: intl.formatMessage({ id: 'Admin.User.errorUsernameInUse' }, {}),
		USERNAME_INVALID: intl.formatMessage({ id: 'Admin.User.errorUsernameInvalid' }, {}),
		UNEXPECTED_ERROR: intl.formatMessage({ id: 'Admin.User.errorUnexpected' }, {}),
		WRONG_PASSWORD: intl.formatMessage({ id: 'Admin.User.errorWrongPassword' }, {}),
		PASSWORD_DONT_MATCH: intl.formatMessage({ id: 'Admin.User.errorPasswordsDontMatch' }, {})
	};

	const getError = (code) => (code ? errorsMappings[code] || errorsMappings.UNEXPECTED_ERROR : undefined);

	return (
		<form>
			<Grid container spacing={2}>
				<Grid item container spacing={1} justifyContent="space-between">
					<Grid item>
						<Typography
							variant="h5"
							gutterBottom
							sx={{ color: (theme) => (theme.palette.mode === 'dark' ? 'white' : '#222222') }}
						>
							<FormattedMessage
								color="textPrimary"
								id="Admin.User.changePassword"
								defaultMessage="Change password"
							/>
						</Typography>
					</Grid>
					{errors.form ? (
						<Grid item>
							<Typography color="error" variant="subtitle1">
								{getError(errors.form)}
							</Typography>
						</Grid>
					) : null}
					<Grid item>
						<Chip icon={<IconUser />} label={user.username} />
					</Grid>
				</Grid>
				{requireOldPassword ? (
					<Grid item xs={12}>
						<TextField
							required
							variant="outlined"
							size="small"
							id="old-password"
							label={
								<FormattedMessage id="Admin.User.labelOldPassword" defaultMessage="Current password" />
							}
							type="password"
							fullWidth
							error={!!errors.oldPassword}
							helperText={getError(errors.oldPassword)}
							disabled={disabled}
							value={oldPassword}
							onChange={(event) => onOldPasswordUpdate(event.target.value)}
						/>
					</Grid>
				) : null}
				<Grid item xs={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="new-password"
						label={<FormattedMessage id="Admin.User.labelNewPassword" defaultMessage="New password" />}
						type="password"
						fullWidth
						error={!!errors.password}
						disabled={disabled}
						value={newPassword}
						onChange={(event) => onNewPasswordUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="new-password-confirmation"
						label={
							<FormattedMessage id="Admin.User.labelPasswordConfirm" defaultMessage="Confirm password" />
						}
						type="password"
						fullWidth
						error={!!errors.password}
						helperText={getError(errors.password)}
						disabled={disabled}
						value={passwordConfirmation}
						onChange={(event) => onPasswordConfirmationUpdate(event.target.value)}
					/>
				</Grid>
				<Grid container item spacing={2} xs={12} justifyContent="flex-end" direction="row">
					<Grid item>
						<Button variant="outlined" disabled={disabled} onClick={onCancel}>
							<FormattedMessage id="Admin.User.buttonCancel" defaultMessage="Cancel" />
						</Button>
					</Grid>
					<Grid item>
						<Button variant="outlined" onClick={onSubmit} disabled={!valid || disabled}>
							<FormattedMessage id="Admin.User.buttonSave" defaultMessage="Save" />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

UpdatePasswordForm.propTypes = {
	user: userShape.isRequired,
	valid: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	newPassword: PropTypes.string.isRequired,
	errors: PropTypes.shape({
		password: PropTypes.string
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	passwordConfirmation: PropTypes.string.isRequired,
	onNewPasswordUpdate: PropTypes.func.isRequired,
	onPasswordConfirmationUpdate: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

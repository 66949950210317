import { clearHashProperty, setHash, toHashObject } from './URLHash';

export const parseSidePanelHash = (hash) => {
	const { panel } = toHashObject(hash);
	if (panel) {
		const [, id, data] = panel.split('/');
		return { id, data };
	}
	return { id: null };
};
export const setSidePanelHashString = (navigate, panelHashPart) => setHash(navigate, { panel: panelHashPart });

export const setSidePanelHash = (navigate, id, data) =>
	setSidePanelHashString(navigate, id ? `/${[id, data].filter((s) => !!s).join('/')}` : undefined);

export const clearSidePanelHash = (navigate) => clearHashProperty(navigate, 'panel');

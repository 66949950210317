import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/esm/ErrorOutline';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import JSG from '@cedalo/jsg-ui';
import { graphManager } from '../../GraphManager';
import gatewayClient from '../../helper/GatewayClient';

const styles = {
	centerItems: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	// tried to hide scrollbars or look a bit nicer
	scrollbars: {
		overflow: 'auto',
		scrollbarWidth: 'none', // 'thin', //
		'&::-webkit-scrollbar': {
			display: 'none'
		}
	}
};

const getSheetId = () => {
	const sheetView = graphManager.getActiveSheetView();
	const streamsheet = sheetView.getItem();
	const streamsheetContainer = streamsheet && streamsheet.getStreamSheetContainer();
	return streamsheetContainer && streamsheetContainer instanceof JSG.StreamSheetContainer
		? streamsheetContainer.properties.sheetId
		: undefined;
};
const getCellReference = () => {
	const sheetView = graphManager.getActiveSheetView();
	const selection = sheetView.getOwnSelection();
	const selectedRange = selection && selection.getRanges()[0];
	if (selectedRange) {
		const range = selectedRange.copy();
		range.shiftToSheet();
		return range.toString();
	}
	return undefined;
};
const handleResponse = ({ machineserver } = {}) => {
	if (!machineserver || machineserver.error) {
		throw new Error('Failed to fetch cell payload data!');
	}
	return JSON.parse(machineserver.rawvalue);
};
const fetchPayload = async (reference, streamsheetId) => {
	const machineId = graphManager.getMachineId();
	if (!reference) reference = getCellReference();
	if (!streamsheetId) streamsheetId = getSheetId();
	if (machineId && streamsheetId && reference) {
		const response = await gatewayClient.getCellRawValue(machineId, streamsheetId, reference);
		return handleResponse(response);
	}
	const error = `Required data missing! machine: ${machineId}, streamsheet: ${streamsheetId}, reference: ${reference}`;
	throw new Error(error);
};

const prettyPrint = (json) => (
	<div style={{ padding: '0px 10px', maxWidth: '300px', maxHeight: '400px', overflow: 'auto'}}>
		<pre>
			<Typography variant="inherit">{JSON.stringify(json, null, 2)}</Typography>
		</pre>
	</div>
);
const showError = (/* error */) => (
	<div style={{ ...styles.centerItems }}>
		<ErrorIcon style={{ color: 'red', width: '36px', height: '36px', marginTop: '15px' }} />
		<Typography style={{ margin: '10px' }}>
			<FormattedMessage id="Request.Failed" defaultMessage="Request failed" />
		</Typography>
	</div>
);
const fetchingFeedback = () => (
	<div style={{ ...styles.centerItems, width: '125px', height: '150px' }}>
		<CircularProgress />
	</div>
);
const payloadContent = (payload) => (payload.error ? showError(payload.error) : prettyPrint(payload.json));
const getContent = (payload) =>	payload ? payloadContent(payload) : fetchingFeedback();


const CellPayloadPopover = (props) => {
	const { open, anchor, reference, streamsheetId, onClose } = props;
	const [payload, setPayload] = useState(null);

	useEffect(() => {
		const getPayload = async () => {
			try {
				const pl = await fetchPayload(reference, streamsheetId);
				setPayload({ json: pl });
			} catch (error) {
				setPayload({ error: error.message });
			}
		};
		if (open) {
			getPayload();
			setPayload(null);
		}
	}, [open]);

	if (!open) {
		return null;
	}

	return (
		<Popover
			// sx={{ ...styles.scrollbars}}
			open={open}
			onClose={onClose}
			anchorEl={anchor}
			// disableScrollLock
			anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
			transformOrigin={{ vertical: 'top', horizontal: 'left' }}
		>
			{getContent(payload)}
		</Popover>
	);
};

export default CellPayloadPopover;

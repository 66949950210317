import { ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import React from 'react';
import { HttpServerPluginSettings } from './http-server';
import { OpcuaServerPluginSettings } from './opcua-server';
import { MachineDataPluginSettings } from './machinedata';

const Plugins = [HttpServerPluginSettings, OpcuaServerPluginSettings, MachineDataPluginSettings];

const withPlugin = (fn) => (id) => {
	const plugin = Plugins.find((p) => p.id === id);
	return plugin ? fn(plugin) : null;
};

export const getPluginSettingsComponent = withPlugin((plugin) => plugin.panel);

export const getPluginPanelSize = withPlugin((plugin) => plugin.panelSize);

export const hasPluginSettings = (id) => Plugins.some((p) => p.id === id);

export const PluginSettingItems = ({ onClick }) =>
	Plugins.map(({ id, menuItemIcon, menuItemText }) => (
		<MenuItem dense key={id} onClick={() => onClick(id)}>
			<ListItemIcon>{menuItemIcon}</ListItemIcon>
			<ListItemText>{menuItemText}</ListItemText>
		</MenuItem>
	));

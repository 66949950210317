import { intl } from '@cedalo/webui/src/helper/IntlGlobalProvider';
import { runQuery, useQuery } from '@cedalo/webui/src/ui/app/GraphQLWSClient';
import { hasFieldError } from '@cedalo/webui/src/ui/utils/filterAndSort';
import { Overlay } from '@cedalo/webui/src/ui/utils/Overlay';
import { UnsavedChangesDialog } from '@cedalo/webui/src/ui/utils/UnsavedChangesDialog';
import CheckIcon from '@mui/icons-material/esm/Check';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer } from 'react';
import { FormattedMessage } from 'react-intl';

const workspaceShape = PropTypes.shape({
	name: PropTypes.string
});

export const UpdateWorkspaceForm = (props) => {
	const { errors, workspace, valid, disabled, pristine, onNameUpdate, onCancel, onSubmit } = props;

	const errorsMappings = {
		NAME_INVALID: intl.formatMessage({ id: 'Extensions.Admin.Workspace.errorNameInvalid' }, {}),
		NAME_IN_USE: intl.formatMessage({ id: 'Extensions.Admin.Workspace.errorNameInUse' }, {}),
		NAME_REQUIRED: intl.formatMessage({ id: 'Extensions.Admin.Workspace.errorNameRequired' }, {}),
		UNEXPECTED_ERROR: intl.formatMessage({ id: 'Extensions.Admin.Workspace.errorUnexpected' }, {})
	};
	const getError = (code) => (code ? errorsMappings[code] || errorsMappings.UNEXPECTED_ERROR : undefined);

	return (
		<form>
			<Grid container spacing={2}>
				<Grid item container spacing={8} justifyContent="space-between">
					<Grid item />
					{errors.form ? (
						<Grid item>
							<Typography color="error" variant="subtitle1">
								{getError(errors.form)}
							</Typography>
						</Grid>
					) : null}
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="name"
						label={<FormattedMessage id="Extensions.Admin.Workspace.labelName" defaultMessage="Name" />}
						fullWidth
						error={!!errors.name}
						helperText={getError(errors.name)}
						disabled={disabled}
						value={workspace.name}
						onChange={(event) => onNameUpdate(event.target.value)}
					/>
				</Grid>

				<Grid container item spacing={2} xs={12} justifyContent="flex-end" direction="row">
					{onCancel ? (
						<Grid item>
							<Button variant="outlined" onClick={onCancel} disabled={disabled}>
								<FormattedMessage
									id="Extensions.Admin.Workspace.buttonCancel"
									defaultMessage="Cancel"
								/>
							</Button>
						</Grid>
					) : null}
					<Grid item>
						<Button
							// className={classes.button}
							variant="outlined"
							onClick={onSubmit}
							disabled={pristine || disabled || !valid}
						>
							<FormattedMessage id="Extensions.Admin.Workspace.buttonSave" defaultMessage="Save" />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
	);
};

UpdateWorkspaceForm.propTypes = {
	workspace: workspaceShape.isRequired,
	errors: PropTypes.shape({
		name: PropTypes.string
	}).isRequired,
	pristine: PropTypes.bool.isRequired,
	valid: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	onNameUpdate: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

const QUERY = `
query FindWorkspace($id: ID!) {
	workspace(id: $id) {
		id
		name
		users {
			id
			username
			email
			firstName
			lastName
		}
	}
}
`;

const UPDATE_WORKSPACE_MUTATION = `
mutation UpdateWorkspace($id: ID!, $workspace: UpdateWorkspaceInput!) {
	updateWorkspace(id: $id, workspace: $workspace) {
		workspace {
			id
			name
		}
		success
		code
		fieldErrors {
			name
		}
	}
}
`;

const updateWorkspaceReducer = (state, action) => {
	switch (action.type) {
		case 'set_initial_workspace':
			return {
				...state,
				pristine: true,
				workspace: {
					name: action.data.name || ''
				},
				originalWorkspace: action.data
			};
		case 'set_name':
			return {
				...state,
				pristine: false,
				workspace: { ...state.workspace, name: action.data },
				errors: {
					...state.errors,
					name: !action.data ? 'NAME_REQUIRED' : undefined
				}
			};
		case 'save':
			return {
				...state,
				savePending: true
			};
		case 'saving_error':
			return {
				...state,
				savePending: false,
				errors: action.data
			};
		case 'saving_success':
			return {
				...state,
				pristine: true,
				savePending: false,
				saved: true,
				errors: {}
			};
		case 'reset_save_success':
			return {
				...state,
				saved: false,
				pristine: true,
				errors: {}
			};
		default:
			throw new Error(`Unkown action '${action.type}'`);
	}
};

export const EditWorkspaceView = (props) => {
	const { onCancel, workspaceId } = props;
	const workspaceQuery = useQuery(QUERY, { id: workspaceId });
	const loading = workspaceQuery.status === 'loading';
	const [state, dispatch] = useReducer(updateWorkspaceReducer, {
		pristine: true,
		workspace: {
			name: ''
		},
		originalWorkspace: {
			name: ''
		},
		errors: {},
		savePending: false,
		saved: false
	});

	useEffect(() => {
		if (workspaceQuery.status === 'done' && workspaceQuery.result.workspace) {
			dispatch({ type: 'set_initial_workspace', data: workspaceQuery.result.workspace });
		}
	}, [workspaceQuery]);

	useEffect(() => {
		if (state.saved) {
			setTimeout(() => {
				dispatch({ type: 'reset_save_success' });
			}, 500);
		}
	}, [state.saved]);

	if (workspaceQuery.errors) {
		return (
			<div
				style={{
					height: '100%',
					padding: '24px',
					backgroundColor: '#EEE',
					boxSizing: 'border-box',
					overflow: 'auto'
				}}
			>
				{workspaceQuery.result.join('\n')}
			</div>
		);
	}

	const saveWorkspace = async () => {
		dispatch({ type: 'save' });
		try {
			const {
				updateWorkspace: { success, fieldErrors, code, workspace }
			} = await runQuery(UPDATE_WORKSPACE_MUTATION, {
				id: workspaceId,
				workspace: state.workspace
			});
			if (success) {
				dispatch({ type: 'saving_success', data: workspace });
			} else if (fieldErrors) {
				dispatch({ type: 'saving_error', data: fieldErrors });
			} else {
				dispatch({ type: 'saving_error', data: { form: code } });
			}
		} catch (error) {
			dispatch({ type: 'saving_error', data: { form: 'UNEXPECTED_ERROR' } });
			console.error(error);
		}
	};

	const hasError = hasFieldError(state.errors);
	const hasRequired = state.workspace.name;
	const isValid = !!(!hasError && hasRequired);
	const showProgress = loading || state.savePending;
	const showSuccess = state.saved;

	return (
		<div>
			<UnsavedChangesDialog when={!state.pristine} />
			<UpdateWorkspaceForm
				workspace={state.workspace}
				originalWorkspace={state.originalWorkspace}
				pristine={state.pristine}
				errors={state.errors}
				disabled={loading || state.savePending || state.saved}
				onCancel={onCancel}
				valid={isValid}
				onSubmit={saveWorkspace}
				onNameUpdate={(value) => dispatch({ type: 'set_name', data: value })}
			/>
			{showProgress && (
				<Overlay>
					<CircularProgress style={{ width: '24px', height: '24px' }} />
				</Overlay>
			)}
			{showSuccess && (
				<Overlay>
					<CheckIcon color="primary" />
				</Overlay>
			)}
		</div>
	);
};

EditWorkspaceView.propTypes = {
	workspaceId: PropTypes.string.isRequired
};

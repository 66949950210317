import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { userShape } from './UserPropTypes';
import { UserWorkspaceTable } from './UserWorkspaceTable';

export const CreateUserForm = (props) => {
	const {
		user,
		errors,
		valid,
		disabled,
		pristine,
		passwordConfirmation,
		intl,
		onUsernameUpdate,
		onEmailUpdate,
		onFirstNameUpdate,
		onLastNameUpdate,
		onPasswordUpdate,
		onPasswordConfirmationUpdate,
		onWorkspaceUpdate,
		onRoleUpdate,
		onWorkspaceDelete,
		onWorkspaceAdd,
		onAdminUpdate,
		workspaceOptions,
		roleOptions,
		showWorkspace,
		showAdmin,
		onCancel,
		onSubmit
	} = props;

	const errorsMappings = {
		EMAIL_INVALID: intl.formatMessage({ id: 'Admin.User.errorEMailInvalid' }, {}),
		EMAIL_IN_USE: intl.formatMessage({ id: 'Admin.User.errorEMailInUse' }, {}),
		EMAIL_REQUIRED: intl.formatMessage({ id: 'Admin.User.errorEMailRequired' }, {}),
		USERNAME_REQUIRED: intl.formatMessage({ id: 'Admin.User.errorUsernameRequired' }, {}),
		USERNAME_IN_USE: intl.formatMessage({ id: 'Admin.User.errorUsernameInUse' }, {}),
		USERNAME_INVALID: intl.formatMessage({ id: 'Admin.User.errorUsernameInvalid' }, {}),
		UNEXPECTED_ERROR: intl.formatMessage({ id: 'Admin.User.errorUnexpected' }, {}),
		PASSWORD_DONT_MATCH: intl.formatMessage({ id: 'Admin.User.errorPasswordsDontMatch' }, {})
	};

	const getError = (code) => (code ? errorsMappings[code] || errorsMappings.UNEXPECTED_ERROR : undefined);

	return (
        <form>
			<Grid container spacing={2}>
				<Grid item container spacing={1} justifyContent="space-between" alignItems="center">
					<Grid item />
					{errors.form ? (
						<Grid item>
							<Typography color="error" variant="subtitle1">
								{getError(errors.form)}
							</Typography>
						</Grid>
					) : undefined}
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="username"
						label={<FormattedMessage id="Admin.User.labelUsername" defaultMessage="Username" />}
						fullWidth
						error={!!errors.username}
						helperText={getError(errors.username)}
						disabled={disabled}
						value={user.username || ''}
						onChange={(event) => onUsernameUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="email"
						label={<FormattedMessage id="Admin.User.labelEMail" defaultMessage="E-Mail" />}
						fullWidth
						error={!!errors.email}
						helperText={getError(errors.email)}
						disabled={disabled}
						value={user.email || ''}
						onChange={(event) => onEmailUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="firstName"
						variant="outlined"
						size="small"
						label={<FormattedMessage id="Admin.User.labelFirstName" defaultMessage="First name" />}
						fullWidth
						disabled={disabled}
						value={user.firstName || ''}
						onChange={(event) => onFirstNameUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						id="lastName"
						variant="outlined"
						size="small"
						label={<FormattedMessage id="Admin.User.labelLastName" defaultMessage="Last name" />}
						fullWidth
						disabled={disabled}
						value={user.lastName || ''}
						onChange={(event) => onLastNameUpdate(event.target.value)}
					/>
				</Grid>
				{showAdmin ? (
					<Grid item xs={12}>
						<FormControl>
							<FormControlLabel
								control={
									<Checkbox
										checked={user.admin}
										disabled={disabled}
										onChange={(event) => onAdminUpdate(event.target.checked)}
										value="administrator"
									/>
								}
								label={<FormattedMessage id="Admin.User.admin" defaultMessage="Administrator" />}
							/>
							<FormHelperText>
								<FormattedMessage
									id="Admin.User.admin.helper"
									defaultMessage="Give the user full administrative rights"
								/>
							</FormHelperText>
						</FormControl>
					</Grid>
				) : null}
				{!user.admin && showWorkspace ? (
					<Grid item container xs={12}>
						<Grid container item alignItems="center" justifyContent="space-between">
							<Grid item>
								<InputLabel htmlFor="workspaces">
									<FormattedMessage id="Admin.User.workspaces" defaultMessage="Workspaces" />
								</InputLabel>
							</Grid>
							<Grid>
								<Button
									variant="outlined"
									disabled={disabled}
									onClick={() => onWorkspaceAdd()}
								>
									<FormattedMessage id="Admin.User.workspaceAdd" defaultMessage="Add to Workspace" />
								</Button>
							</Grid>
						</Grid>
						{user.scopes.length > 0 ? (
							<Paper variant="outlined" style={{ marginTop: '10px', width: '100%' }}>
								<div style={{margin: '10px'}}>
								<UserWorkspaceTable
									workspaces={user.scopes}
									onWorkspaceUpdate={onWorkspaceUpdate}
									onRoleUpdate={onRoleUpdate}
									onWorkspaceDelete={onWorkspaceDelete}
									disabled={disabled}
									workspaceOptions={workspaceOptions}
									roleOptions={roleOptions}
								/>
								</div>
							</Paper>
						) : (
							<div style={{ margin: '16px 0px 0px', width: '100%' }}>
								<Typography color="text.primary" variant="body2" algin="center">
									<FormattedMessage
										id="Admin.User.noWorkspaceAssigned"
										defaultMessage="The user has no workspace assigned and will not be able to login. Please add the user to a workspace."
									/>
								</Typography>
							</div>
						)}
					</Grid>
				) : null}
				<Grid item xs={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="password"
						label={<FormattedMessage id="Admin.User.labelPassword" defaultMessage="Password" />}
						type="password"
						fullWidth
						disabled={disabled}
						error={!!errors.password}
						value={user.password || ''}
						onChange={(event) => onPasswordUpdate(event.target.value)}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						required
						variant="outlined"
						size="small"
						id="password-confirmation"
						type="password"
						label={
							<FormattedMessage id="Admin.User.labelPasswordConfirm" defaultMessage="Confirm password" />
						}
						error={!!errors.password}
						helperText={getError(errors.password)}
						fullWidth
						disabled={disabled}
						value={passwordConfirmation || ''}
						onChange={(event) => onPasswordConfirmationUpdate(event.target.value)}
					/>
				</Grid>
				<Grid container item xs={12} justifyContent="flex-end" direction="row">
					<Grid item>
						<Button variant="outlined" disabled={disabled} onClick={onCancel}>
							<FormattedMessage id="Admin.User.buttonCancel" defaultMessage="Cancel" />
						</Button>
					</Grid>
					<Grid item style={{ paddingLeft: '16px' }}>
						<Button
							variant="outlined"
							onClick={onSubmit}
							disabled={pristine || !valid || disabled}
						>
							<FormattedMessage id="Admin.User.buttonAdd" defaultMessage="Add" />
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</form>
    );
};

CreateUserForm.propTypes = {
	user: userShape.isRequired,
	errors: PropTypes.shape({
		username: PropTypes.string,
		email: PropTypes.string,
		password: PropTypes.string
	}).isRequired,
	valid: PropTypes.bool.isRequired,
	disabled: PropTypes.bool.isRequired,
	passwordConfirmation: PropTypes.string.isRequired,
	pristine: PropTypes.bool.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	// onFieldUpdate: PropTypes.func.isRequired,
	onUsernameUpdate: PropTypes.func.isRequired,
	onEmailUpdate: PropTypes.func.isRequired,
	onFirstNameUpdate: PropTypes.func.isRequired,
	onLastNameUpdate: PropTypes.func.isRequired,
	onPasswordUpdate: PropTypes.func.isRequired,
	onPasswordConfirmationUpdate: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	onCancel: PropTypes.func.isRequired
};

import * as Actions from '@cedalo/webui/src/actions/actions';
import qs from 'query-string';
import { useEffect } from 'react';
import { connect } from 'react-redux';

export const ScopeHandler = connect(
	({ user }) => ({
		scopeId: user.user && user.user.scope ? user.user.scope.id : null
	}),
	{ setScope: Actions.setScope }
)((props) => {
	const { setScope, scopeId } = props;

	useEffect(() => {
		if (scopeId !== null) {
			const currentHash = qs.parse(window.location.hash);
			const hashObject = { ...currentHash, scope: scopeId };
			const hashString = qs.stringify(hashObject);
			window.location.hash = hashString;
		}
	}, [scopeId]);

	useEffect(() => {
		const eventHandler = () => {
			const hash = qs.parse(window.location.hash);
			if (hash.scope && hash.scope !== scopeId) {
				setScope({ id: hash.scope });
			}
		};
		window.addEventListener('hashchange', eventHandler);
		return () => {
			window.removeEventListener('hashchange', eventHandler);
		};
	}, [scopeId]);
	return null;
});

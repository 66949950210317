import gatewayClient from '@cedalo/webui/src/helper/GatewayClient';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup } from '@mui/material';
import DialogButton from '@cedalo/webui/src/ui/utils/DialogButton';
import { StreamSheetAccordion } from '@cedalo/webui/src/ui/utils/StreamSheetAccordion';

const PATH = 'share';

const SHARE_MACHINE = `
mutation ShareMachine($machineId: ID!, $tsExpiry: Float!, $visibility: Visibility!) {
	shareMachine(machineId: $machineId, tsExpiry: $tsExpiry, visibility: $visibility) {
		code,
		success,
		link {
			id
		}
	}
}
`;
const UNSHARE_MACHINE = `
mutation UnshareMachine($machineId: ID!) {
	unshareMachine(machineId: $machineId)
}
`;
const SHARED_MACHINE = `
query SharedMachine($id: ID!, $type: IdType) {
	sharedMachine(id: $id, type: $type) {
		code,
		success,
		link {
			id,
			tsExpiry,
			visibility
		}
	}
}
`;

const DAY = 24 * 60 * 60 * 1000;
const defaultExpiry = () => Date.now() + DAY;
const defaultLink = () => ({
	id: '',
	tsExpiry: defaultExpiry(),
	visibility: 'logged_in'
});

const getHostOrigin = () => {
	if (!window.location) throw new Error('Failed to determine host origin!');
	return window.location.origin;
};
const msToDate = (ms) => (ms > 0 ? new Date(ms) : new Date(defaultExpiry()));
// eslint-disable-next-line no-nested-ternary
const timeReducer = (time, curr, index) => (index === 0 ? curr : index === 1 ? `${time}:${curr}` : time);
const getDate = (ms) => msToDate(ms).toISOString().split('T')[0] || '';
const getTime = (ms) => msToDate(ms).toTimeString().split(':').reduce(timeReducer, '');
const getExpiry = (date, time) => {
	date = date || getDate(defaultExpiry());
	time = time || getTime(defaultExpiry());
	return Date.parse(`${date}T${time}:00`);
};

const queryGraphQL = async (query, variables, onResponse) => {
	try {
		const response = await gatewayClient.graphql(query, variables);
		onResponse(response);
	} catch (error) {
		onResponse(undefined, error);
	}
};

export default function ShareAccordion(props) {
	const { appId, expanded } = props;
	const [lookup, setLookup] = useState(true);
	const [sharedLink, setSharedLink] = useState(defaultLink());

	const updateExpiry = (tsExpiry) => setSharedLink({ ...sharedLink, id: '', tsExpiry });

	const updateVisibility = (ev) => {
		setSharedLink({ ...sharedLink, id: '', visibility: ev.target.value });
	};

	const copy = () => {
		const focus = document.activeElement;
		const textarea = document.createElement('textarea');

		// Place in top-left corner of screen regardless of scroll position.
		textarea.style.position = 'fixed';
		textarea.style.top = 0;
		textarea.style.left = 0;

		// Ensure it has a small width and height. Setting to 1px / 1em
		// doesn't work as this gives a negative w/h on some browsers.
		textarea.style.width = '1px';
		textarea.style.height = '1px';
		textarea.style.padding = 0;
		textarea.style.border = 'none';
		textarea.style.outline = 'none';
		textarea.style.boxShadow = 'none';
		textarea.style.background = 'transparent';

		document.body.appendChild(textarea);

		/* Copy the text inside the text field */
		textarea.value = `${getHostOrigin()}/${PATH}/${sharedLink.id}`;
		textarea.select();
		document.execCommand('Copy');
		document.body.removeChild(textarea);
		focus.focus();
	};

	const openInTab = () => {
		window.open(`${getHostOrigin()}/${PATH}/${sharedLink.id}`, '_blank');
	};

	const share = () => {
		const { tsExpiry, visibility } = sharedLink;
		queryGraphQL(SHARE_MACHINE, { machineId: appId, tsExpiry, visibility }, ({ shareMachine = {} } = {}) => {
			const { link, success } = shareMachine;
			if (success) setSharedLink({ ...sharedLink, id: link.id });
		});
	};

	const unshare = () => {
		queryGraphQL(UNSHARE_MACHINE, { machineId: appId }, (data) => {
			if (data) setSharedLink({ ...sharedLink, id: '' });
		});
	};

	const lookupShared = () => {
		if (expanded === 'panelShare') {
			queryGraphQL(SHARED_MACHINE, { id: appId, type: 'machine' }, ({ sharedMachine } = {}) => {
				const { link, success } = sharedMachine || { success: false };
				// use defaultLink to remove any previous created link which might has been deleted in-between...
				setSharedLink(success ? { ...sharedLink, ...link } : defaultLink());
				setLookup(false);
			});
		}
	};
	useEffect(() => {
		lookupShared();
	}, [expanded]);

	return (
		<StreamSheetAccordion
			id="panelShare"
			expanded={expanded === 'panelShare'}
			onChange={() => props.handleExpanded('panelShare')}
			title={<FormattedMessage id="Extensions.ShareMachine.Title" defaultMessage="Share App" />}
			details={
				props.appEditable ? (
					<FormattedMessage id="Extensions.ShareMachine.Details" defaultMessage="Create link to share App" />
				) : null
			}
		>
			<FormGroup>
				{lookup ? (
					<Typography style={{ marginBottom: '5px' }}>
						<FormattedMessage
							id="Extensions.ShareMachine.LookupText"
							defaultMessage="Lookup existing link. Please wait..."
						/>
					</Typography>
				) : null}
				{!props.appEditable && !sharedLink.id ? (
					<Typography style={{ marginBottom: '5px' }}>
						<FormattedMessage
							id="Extensions.ShareMachine.ShareLink"
							defaultMessage="Share link not defined"
						/>
					</Typography>
				) : null}
				{props.appEditable || sharedLink.id
					? [
							<Grid container spacing={2}>
								<Grid item xs={6}>
									<TextField
										variant="outlined"
										size="small"
										fullWidth
										margin="normal"
										type="date" // or datetime-local
										disabled={!!sharedLink.id || sharedLink.tsExpiry < 0}
										value={getDate(sharedLink.tsExpiry)}
										onChange={(ev) =>
											updateExpiry(getExpiry(ev.target.value, getTime(sharedLink.tsExpiry)))
										}
										label={
											<FormattedMessage
												id="Extensions.ShareMachine.ExpireDate"
												defaultMessage="Expiry Date"
											/>
										}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										variant="outlined"
										size="small"
										margin="normal"
										type="time" // or datetime-local
										disabled={!!sharedLink.id || sharedLink.tsExpiry < 0}
										fullWidth
										value={getTime(sharedLink.tsExpiry)}
										onChange={(ev) =>
											updateExpiry(getExpiry(getDate(sharedLink.tsExpiry), ev.target.value))
										}
										label={
											<FormattedMessage
												id="Extensions.ShareMachine.ExpireTime"
												defaultMessage="Expiry Time"
											/>
										}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
							</Grid>,
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={sharedLink.tsExpiry < 0}
										onChange={(ev, checked) => updateExpiry(checked ? -1 : defaultExpiry())}
									/>
								}
								disabled={!!sharedLink.id}
								label={
									<Typography
										color="textSecondary"
										style={{ lineHeight: 'normal', fontSize: '0.65rem' }}
									>
										<FormattedMessage
											id="Extensions.ShareMachine.ExpireNever"
											defaultMessage="Expire Never"
										/>
									</Typography>
								}
							/>,
							<TextField
								label={
									<FormattedMessage
										id="Extensions.ShareMachine.Visibility"
										defaultMessage="Share with"
									/>
								}
								value={sharedLink.visibility}
								margin="normal"
								variant="outlined"
								select
								size="small"
								onChange={updateVisibility}
								disabled={!!sharedLink.id}
								name="visibility"
							>
								<MenuItem value="logged_in">
									<FormattedMessage
										id="Extensions.ShareMachine.Visibility.LoggedIn"
										defaultMessage="Streamsheets Users"
									/>
								</MenuItem>
								<MenuItem value="public">
									<FormattedMessage
										id="Extensions.ShareMachine.Visibility.Anyone"
										defaultMessage="Anyone"
									/>
								</MenuItem>
							</TextField>
					  ]
					: null}
				{sharedLink.id
					? [
							<TextField
								label={<FormattedMessage id="Extensions.ShareMachine.Link" defaultMessage="Link" />}
								type="text"
								fullWidth
								size="small"
								multiline
								variant="outlined"
								margin="normal"
								value={`${getHostOrigin()}/${PATH}/${sharedLink.id}`}
								InputProps={{ readOnly: true }}
							/>,
							<DialogButton marginV sx={{ width: '100%' }} onClick={copy}>
								<FormattedMessage
									id="Extensions.ShareMachine.Copy"
									defaultMessage="Copy to clipboard"
								/>
							</DialogButton>,
							<DialogButton marginV sx={{ width: '100%' }} onClick={openInTab}>
								<FormattedMessage id="Extensions.ShareMachine.Open" defaultMessage="Open in Tab" />
							</DialogButton>
					  ]
					: null}

				{sharedLink.id && props.appEditable ? (
					<DialogButton marginV sx={{ width: '100%' }} onClick={unshare}>
						<FormattedMessage id="Extensions.ShareMachine.Clear" defaultMessage="Delete" />
					</DialogButton>
				) : null}
				{!sharedLink.id && props.appEditable ? (
					<DialogButton sx={{ width: '100%' }} onClick={share}>
						<FormattedMessage id="Extensions.ShareMachine.Create" defaultMessage="Create" />
					</DialogButton>
				) : null}
			</FormGroup>
		</StreamSheetAccordion>
	);
}

import React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { FormattedMessage, injectIntl } from 'react-intl';
import Dropzone from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import DialogButton from '@cedalo/webui/src/ui/utils/DialogButton';
import {Divider} from '@mui/material';

// import './styles.css';

const imageMaxSize = 1000000000 // bytes
const acceptedFileTypes = 'image/x-png, image/png, image/jpg, image/jpeg, image/gif';
const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {return item.trim()});

// const extractImageFileExtensionFromBase64 = (base64Data) => {
// 	return base64Data.substring('data:image/'.length, base64Data.indexOf(';base64'))
// }

function resizeImage(base64Str, maxWidth = 400, maxHeight = 350) {
	return new Promise((resolve) => {
	  const img = new Image();
	  img.src = base64Str;
	  img.onload = () => {
		const canvas = document.createElement('canvas');
		const MAX_WIDTH = maxWidth;
		const MAX_HEIGHT = maxHeight;
		let width = img.width;
		let height = img.height;

		if (width > height) {
		  if (width > MAX_WIDTH) {
			height *= MAX_WIDTH / width;
			width = MAX_WIDTH;
		  }
		} else if (height > MAX_HEIGHT) {
			width *= MAX_HEIGHT / height;
			height = MAX_HEIGHT;
		}
		
		canvas.width = width;
		canvas.height = height;
		const ctx = canvas.getContext('2d');
		ctx.drawImage(img, 0, 0, width, height);
		resolve(canvas.toDataURL());
	  }
	})
  }

const getCroppedImg = (imageData, crop, canvas) => {

	if (crop.x === 0 || crop.y === 0) {
		return imageData;
	}
	const image = document.createElement('img');
	image.src = imageData;

	const scaleX = image.naturalWidth / image.width;
	const scaleY = image.naturalHeight / image.height;
	canvas.width = crop.width;
	canvas.height = crop.height;
	const ctx = canvas.getContext("2d");

	const pixelRatio = window.devicePixelRatio;
	canvas.width = crop.width * pixelRatio;
	canvas.height = crop.height * pixelRatio;
	ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
	ctx.imageSmoothingQuality = "high";


	ctx.drawImage(
	  image,
	  crop.x * scaleX,
	  crop.y * scaleY,
	  crop.width * scaleX,
	  crop.height * scaleY,
	  0,
	  0,
	  crop.width,
	  crop.height
	);
	const base64Image = canvas.toDataURL("image/jpeg");
	return base64Image;
  }

const image64toCanvasRef = (canvasRef, image64) => {
	const image = new Image();
	image.src = image64;
  }

class ImageUploadDialog extends React.Component {

	constructor(props) {
		super(props);
		this.imagePreviewCanvasRef = React.createRef();
        this.fileInputRef = React.createRef();
        this.state = {
            imgSrc: null,
            // imgSrcExt: null,
            crop: {
                aspect: 16 / 9
            }
        };
	}

	verifyFile = (files) => {
        if (files && files.length > 0){
            const currentFile = files[0]
            const currentFileType = currentFile.type
            const currentFileSize = currentFile.size
            if(currentFileSize > imageMaxSize) {
				// TODO: show warning
                return false;
            }
            if (!acceptedFileTypesArray.includes(currentFileType)) {
				// TODO: show warning
                return false;
            }
            return true;
		}
		return false;
    }



	handleOnDrop = (files, rejectedFiles) => {
        if (rejectedFiles && rejectedFiles.length > 0){
            this.verifyFile(rejectedFiles)
        }

        if (files && files.length > 0){
             const isVerified = this.verifyFile(files);
             if (isVerified) {
                 const currentFile = files[0];
                 const fileReader = new FileReader();
                 fileReader.addEventListener('load', async () => {
                     const result = await resizeImage(fileReader.result, 800, 450);

                     this.setState({
                         imgSrc: result,
                         image: result,
                        //  imgSrcExt: extractImageFileExtensionFromBase64(result)
                     });
                 }, false)
                 fileReader.readAsDataURL(currentFile);
             }
        }
	}

	handleImageLoaded = (/* image */) => {
	}

    handleOnCropChange = (crop) => {
        this.setState({
			crop
		});
	}

    handleOnCropComplete = (crop) =>{
        const canvasRef = this.imagePreviewCanvasRef.current;
        const { imgSrc }  = this.state;
		const croppedImage = getCroppedImg(imgSrc, crop, this.imagePreviewCanvasRef.current);
		this.setState({
			image: croppedImage
		});
        image64toCanvasRef(canvasRef, croppedImage);
	}

	handleClearToDefault = (event) =>{
        if (event) {
			event.preventDefault();
		}
		const canvas = this.imagePreviewCanvasRef.current;
		if (canvas) {
			const ctx = canvas.getContext('2d');
			ctx.clearRect(0, 0, canvas.width, canvas.height);

			this.setState({
				imgSrc: null,
				// imgSrcExt: null,
				crop: {
					aspect: 16 / 9
				}
			})
			this.fileInputRef.current.value = null;
		}
    }

	handleClose = () => {
		this.handleClearToDefault();
		this.props.onClose();
	}

	handleSubmit = () => {
		this.props.onSubmit({
			...this.state
		});
		this.handleClearToDefault();
	}

	render() {
		const { imgSrc } = this.state;
		const { open, onClose } = this.props;
		return (
			<Dialog
				open={open}
				onClose={onClose}
				maxWidth={false}
			>
				<DialogTitle>
					<FormattedMessage id="DialogUpload.Title" defaultMessage="Set machine image"/>
				</DialogTitle>
				<DialogContent
					style={{
						height: '545px',
						minWidth: '500px',
					}}
				>
					<div>
						<input
							ref={this.fileInputRef}
							type='file'
							accept={acceptedFileTypes}
							multiple={false}
							onChange={this.handleFileSelect}
							style={{
								visibility: 'hidden'
							}}
						/>
						{imgSrc !== null ?
							<div>
								<ReactCrop
									src={imgSrc}
									crop={this.state.crop}
									onImageLoaded={this.handleImageLoaded}
									onComplete = {this.handleOnCropComplete}
									onChange={this.handleOnCropChange}
								/>
								<br/>
								<canvas
									style={{ display: 'none' }}
									ref={this.imagePreviewCanvasRef}
								/>
							</div>

						:

							<Dropzone
								onDrop={this.handleOnDrop}
								accept={acceptedFileTypes}
								multiple={false}
								maxSize={imageMaxSize}
								// style={{"width" : "100%", "height" : "500px", "border" : "1px dotted black"}}
								style={{
									width: "100%",
									height: "450px",
									borderWidth: "2px",
									borderColor: "rgb(102, 102, 102)",
									borderStyle: "dashed",
									borderRadius: "5px",
									textAlign: "center",
								}}
							>
								<FormattedMessage
									id="DialogUpload.DropImage"
									defaultMessage="Drop image here or click to upload"
								/>
							</Dropzone>
						}
					</div>
				</DialogContent>
				<Divider/>
				<DialogActions>
					<DialogButton
						onClick={this.handleClose}
						margin
					>
						<FormattedMessage id="Cancel" defaultMessage="Cancel"/>
					</DialogButton>
					<DialogButton
						onClick={this.handleSubmit}
						margin
					>
						<FormattedMessage id="DialogNew.add" defaultMessage="Add" />
				</DialogButton>
				</DialogActions>
			</Dialog>
		);
	}
}

export default injectIntl(ImageUploadDialog);
